import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { getUser, logout } from "shared/auth/Auth";
import { useLoggedUserData } from "data/queries/queryLoggedUser";

export default function ViewportAvatarButton() {
  const { t } = useTranslation();
  const btnID = useId();
  const menuID = useId();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = getUser();
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const loggedUser = useLoggedUserData();
  const photoFileId = getPhotoFileId(loggedUser);

  return [
    <IconButton
      id={btnID}
      key="avatar-button"
      aria-controls={isOpen ? menuID : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? "true" : undefined}
      onClick={handleClick}
      sx={[
        styles.root,
        { bgcolor: isOpen ? "success.light" : "action.selected" },
      ]}
    >
      <Avatar
        sx={{
          width: 36,
          height: 36,
          border: "2px solid rgb(255, 255, 255)",
          bgcolor: user?.picture != null ? null : "primary.dark",
        }}
        alt={user?.name}
        src={
          photoFileId != null
            ? `api/v1/file/${photoFileId}?width=36&height=36`
            : user?.picture
        }
      >
        {user?.name[0].toUpperCase()}
      </Avatar>
    </IconButton>,
    <Menu
      id={menuID}
      key="avatar-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": btnID,
      }}
    >
      <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
        <ListItemText>{t("Logout")}</ListItemText>
      </MenuItem>
    </Menu>,
  ];
}

const styles = {
  root: {
    width: 40,
    height: 40,
  },
};

// TODO: get this streamlined by a backend API
function getPhotoFileId(data) {
  if (
    data.documents != null &&
    data.documents.length > 0 &&
    data.documents[0].file != null
  ) {
    return data.documents[0].file.id;
  }
  return null;
}
