import { FormControl, TextField, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Chip from "@mui/material/Chip";
import fetch from "data/fetch";
import React, { useState, useEffect, useRef, useCallback } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RoleSelect({
  disabled: disabledProp,
  label: labelProp,
  onChange,
  required,
  value,
}) {
  const { t, i18n } = useTranslation();
  const [availableRoles, isSelectable, isLoaded] = useRoles();
  const fixedOptionsRef = useRef([]);

  const label = (labelProp ?? t("Permissions")) + (required ? " *" : "");
  const disabled = disabledProp || !isLoaded;

  if (value != null) {
    fixedOptionsRef.current = value.filter((id) => !isSelectable(id));
    console.log("fixed options = ", fixedOptionsRef.current);
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        disableCloseOnSelect={true}
        disabled={disabled}
        label={label}
        multiple={true}
        onChange={(_e, newValue) => {
          onChange([
            ...fixedOptionsRef.current,
            ...newValue.filter((id) => !fixedOptionsRef.current.includes(id)),
          ]);
        }}
        options={availableRoles}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, id, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {i18n.exists(`role.${id}`) ? t(`role.${id}`) : id}
          </li>
        )}
        renderTags={(value, getCustomizedTagProps) =>
          value.map((id, index) => {
            const tagProps = getCustomizedTagProps({ index });

            if (!isSelectable(id)) {
              tagProps.onDelete = null;
            }

            // role is known to the UI
            if (i18n.exists(`role.${id}`)) {
              tagProps.label = t(`role.${id}`);
            }
            // unknown role, just display the ID and a warning icon
            else {
              tagProps.icon = <ErrorOutlineIcon />;
              tagProps.label = id;
            }

            return <Chip key={index} size="medium" {...tagProps} />;
          })
        }
        value={value}
      />
    </FormControl>
  );
}

function useRoles() {
  const [rolesMap, setRolesMap] = useState(new Map());
  const [availableRoles, setAvailableRoles] = useState([]);
  const isInitializedRef = useRef(false);
  const isLoaded = rolesMap.size > 0;

  const isSelectable = useCallback(
    (id) => {
      const role = rolesMap.get(id);
      return role != null && role.show !== false;
    },
    [rolesMap]
  );

  useEffect(() => {
    if (isInitializedRef.current) {
      return;
    }
    isInitializedRef.current = true;

    fetch("/v1/system-role")
      .then(({ data }) => {
        const rolesMap = new Map();
        const availableRoles = [];

        for (const entry of data) {
          const id = entry.role;
          rolesMap.set(id, entry);

          if (entry.show) {
            availableRoles.push(id);
          }
        }

        setAvailableRoles(availableRoles.sort((a, b) => a.localeCompare(b)));
        setRolesMap(rolesMap);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return [availableRoles, isSelectable, isLoaded];
}
