import { useLoggedUserPermissions, useLoggedUserData } from "data/queries/queryLoggedUser";
import { useCallback, useMemo } from "react";

export default function usePermissions() {
  const permissions = useLoggedUserPermissions();

  return useCallback(
    (actionType, moduleId) => hasPermission(permissions, actionType, moduleId),
    [permissions]
  );
}

export function useRoles() {
  const user = useLoggedUserData();

  return useCallback(
    (role) => hasRole(user?.roles, role),
    [user]
  );
}

export function useRegional() {
  const user = useLoggedUserData();
  return useMemo(() => user?.congregacao?.regional || null, [user]);
}

export function useCongregation() {
  const user = useLoggedUserData();
  return useMemo(() => user?.congregacao || null, [user]);
}

// Helper functions
function hasPermission(permissions, actionType, moduleId) {
  const actionTypeLocal = `${actionType}-local`;
  const actionTypeRegional = `${actionType}-regional`;
  const actionTypeGlobal = `${actionType}-global`;

  return (
    hasPermissionExact(permissions, actionType, moduleId) ||
    hasPermissionExact(permissions, actionTypeLocal, moduleId) ||
    hasPermissionExact(permissions, actionTypeRegional, moduleId) ||
    hasPermissionExact(permissions, actionTypeGlobal, moduleId)
  );
}

function hasPermissionExact(permissions, actionType, moduleId) {
  return (
    permissions.has(`${actionType}:*`) ||
    permissions.has(`*:${moduleId}`) ||
    permissions.has(`${actionType}:${moduleId}`)
  );
}

function hasRole(roles, role) {
  return roles?.includes(role) ?? false;
}
