import { useTranslation } from "react-i18next";
import React, { useId, useRef, useState, forwardRef } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function PhotoUploadField({
  label,
  id: idOverride,
  helperText,
  InputLabelProps,
  FormHelperTextProps,
  onChange: onChangeProp,
  initialValue,
  ...props
}) {
  const id = useId(idOverride);
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  return (
    <FormControl>
      {label != null && label !== "" && (
        <InputLabel
          htmlFor={id}
          id={inputLabelId}
          disableAnimation={true}
          shrink={true}
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id={id}
        label={label}
        fullWidth={false}
        notched={true}
        inputComponent={PhotoInput}
        onChange={(_e, file) => onChangeProp(file)}
        inputProps={{
          initialValue,
        }}
      />
      {helperText != null && helperText !== "" && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

const PhotoInput = forwardRef(function PhotoInput(props, ref) {
  const { initialValue, onChange: onChangeProp } = props;
  const { t } = useTranslation();
  const [photo, setPhoto] = useState(initialValue || null);
  const inputRef = useRef(null);
  const idFile = `${props.id}-file`;

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const photoUrl = URL.createObjectURL(file);
      setPhoto(photoUrl);
      if (onChangeProp) {
        onChangeProp(event, file);
      }
    }
  };

  const handleClearPhoto = (event) => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setPhoto(null);
    if (onChangeProp) {
      onChangeProp(event, null);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minWidth: 0,
        }}
      >
        <PhotoButton
          id={props.id}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          ref={ref}
          component="label"
          role={undefined}
          startIcon={<AccountBoxIcon />}
        >
          {t("Pick a photo")}
          <input
            accept="image/*"
            type="file"
            id={idFile}
            style={{ display: "none" }}
            ref={inputRef}
            onChange={handlePhotoChange}
          />
        </PhotoButton>
        {photo != null && (
          <PhotoButton
            startIcon={<RemoveCircleOutlineIcon />}
            color="secondary"
            onClick={handleClearPhoto}
          >
            {t("Clear photo")}
          </PhotoButton>
        )}
      </Box>
      {photo != null && (
        <Box
          component="img"
          src={photo}
          sx={{
            width: 160,
            height: 160,
            objectFit: "cover",
            my: 2,
            boxShadow: "inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
          }}
        />
      )}
    </Box>
  );
});

const PhotoButton = forwardRef(function PhotoButton(props, ref) {
  return (
    <Button
      ref={ref}
      role={undefined}
      variant="text"
      fullWidth={true}
      disableElevation={true}
      disableFocusRipple={true}
      disableRipple={true}
      sx={{
        padding: "16.5px 14px",
        justifyContent: "flex-start",
      }}
      {...props}
    />
  );
});
