import fetch from "data/fetch";
import { invalidateCultoSecretariasQuery } from "data/queries/culto-secretaria/queryCultoSecretarias";
import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import CultoSecretariaForm from "./components/CultoSecretariaForm.react";

export async function loader({ params }) {
  const { id } = params;

  // Preload congregations and fetch report data
  const response = await fetch(`/v1/relatorio-culto-secretaria/${id}`, {
    method: "GET",
  });

  // If the data is already JSON-parsed, directly use response.data
  const { data } = response;

  await ensureCongregationQueryData();

  return { initialData: data }; // Pass data to the component as initialData
}

export default function CultoSecretariaEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const res = await fetch(`/v1/relatorio-culto-secretaria/${id}`, {
          method: "GET",
        });

        // Directly access the data if already JSON-parsed
        const { data } = res;

        setInitialData(data);
      } catch (e) {
        setError(e.message ?? t("Sorry, an unexpected error has occurred."));
      }
    };

    loadInitialData();
  }, [id, t]);

  const onSubmit = async (formData) => {
    setError(null);

    try {
      const formattedData = {
        ...formData,
        data: formData.data ? formData.data.format("YYYY-MM-DD") : null, // Ensure date is formatted
      };

      await fetch(`/v1/relatorio-culto-secretaria/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedData),
      });

      invalidateCultoSecretariasQuery();
      navigate("/culto-secretaria");
    } catch (e) {
      setError(e.message ?? t("Sorry, an unexpected error has occurred."));
    }
  };

  if (!initialData) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <CultoSecretariaForm
      onSubmit={onSubmit}
      error={error}
      initialValues={initialData}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit Service Secretary Report")}
          links={[
            {
              name: t("Service Secretary"),
              to: "/culto-secretaria",
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
