import * as Auth from "shared/auth/Auth";
import { apiPrefix } from "config/api-prefix";

const NO_CONTENT_STATUS_CODE = 204;

class HttpError extends Error {
  constructor(message, response, apiMessage) {
    super(message);
    this.name = "HttpError";
    this.status = response.status;
    this.response = response;
    this.apiMessage = apiMessage?.message;
  }
}

export default async function fetch(uri, opts = {}) {
  const { headers = {}, skipAuth = false, ...newOpts } = opts;

  if (!headers.Accept) {
    headers.Accept = "application/json";
  }

  if (!Boolean(skipAuth)) {
    const token = await Auth.getValidAccessTokenOrRedirect();
    headers.Authorization = `Bearer ${token}`;
  }

  newOpts.headers = headers;

  const response = await window.fetch(apiPrefix(uri), newOpts);

  if (response.ok) {
    if (
      response.status === NO_CONTENT_STATUS_CODE ||
      response.headers.get("Content-Length") === "0"
    ) {
      return null;
    }

    if (response.headers.get("Content-Type").match(/json/)) {
      return await response.json();
    }

    return await response.blob(); // force
  }

  // check token integrity again
  if (response.status === 401 && !skipAuth) {
    await Auth.getValidAccessTokenOrRedirect();
  }

  let apiMessage;
  try {
    apiMessage = await response.json();
  } catch (_e) {}

  throw new HttpError(
    `An error happened while calling the service uri: ${uri} method: ${opts.method} status: ${response.status}`,
    response,
    apiMessage
  );
}
