import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

// Fetch function with filter support
function fetchCultoSecretarias(page = 0, filter = "") {
  return fetch(
    `/v1/relatorio-culto-secretaria?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  );
}

// Query Key Generator
const getQueryKey = (page = 0, filter = "") => ["culto-secretarias", page, filter];

// Custom hook for fetching Culto Secretarias with filter
export function useCultoSecretariasQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCultoSecretarias(page, filter),
  });
}

// Ensure Query Data for Culto Secretarias
export function ensureCultoSecretariasQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCultoSecretarias(page, filter),
  });
}

// Invalidate Queries for Culto Secretarias
export function invalidateCultoSecretariasQuery() {
  return queryClient.invalidateQueries(["culto-secretarias"]);
}
