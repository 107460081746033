import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  IconButton,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePeopleQuery } from "data/queries/queryPeople";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import fetch from "data/fetch";
import { invalidateDonationsQuery } from "data/queries/treasury/queryDonations";

const OfferingInput = ({ label, value, onChange }) => {
  const { t } = useTranslation();
  return (
    <TextField
      label={t(label)}
      type="text"
      fullWidth
      value={value}
      onChange={(e) => {
        let inputValue = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
          onChange(inputValue);
        }
      }}
      onFocus={(e) => e.target.select()}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      }}
      onWheel={(e) => e.target.blur()}
      required
    />
  );
};

export default function TreasuryDonationNewPage({ layout }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formError, setFormError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const [newEntry, setNewEntry] = useState({
    congregacaoId: null,
    data: "",
    registros: [
      {
        pessoaId: "",
        pessoaName: "",
        valor: 0,
        tipo: "DIZIMO",
        note: "",
      },
    ],
  });

  const { data: peopleData } = usePeopleQuery({
    filter: searchFilter,
  });

  const donationTypes = ["DIZIMO", "OFERTA", "MISSOES", "VOTO", "OUTROS"];

  const handleAddRow = () => {
    setNewEntry({
      ...newEntry,
      registros: [
        ...newEntry.registros,
        { pessoaId: "", pessoaName: "", valor: 0, tipo: "DIZIMO", note: "" },
      ],
    });
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...newEntry.registros];
    updatedRows.splice(index, 1);
    setNewEntry({ ...newEntry, registros: updatedRows });
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...newEntry.registros];
    updatedRows[index][field] = value;
    setNewEntry({ ...newEntry, registros: updatedRows });
  };

  const handleSubmit = async () => {
    if (
      !newEntry.congregacaoId ||
      !newEntry.data ||
      newEntry.registros.length === 0
    ) {
      setFormError(t("All fields are required, including at least one entry."));
      return;
    }

    setFormError(null);
    setIsSubmitting(true);

    try {
      await Promise.all(
        newEntry.registros.map(async (entry) => {
          await fetch("/v1/doacoes", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              pessoaId: entry.pessoaId === "anonymous" ? null : entry.pessoaId,
              congregacaoId: newEntry.congregacaoId,
              data: newEntry.data,
              valor: parseFloat(entry.valor) || 0,
              tipo: entry.tipo,
              note: entry.note,
            }),
          });
        })
      );
      invalidateDonationsQuery();
      navigate("/treasury/donation");
    } catch (error) {
      setFormError(t("Failed to submit donation entries."));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      onClose={() => navigate("/treasury/donation")}
    >
      <DialogTitle>{t("New Donation")}</DialogTitle>
      <DialogContent>
        {formError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {formError}
          </Alert>
        )}

        <Grid container spacing={2} sx={{ mb: 3, mt: 3 }}>
          <Grid item xs={12} md={6}>
            <CongregationSelect
              required
              onChange={(value) =>
                setNewEntry({
                  ...newEntry,
                  congregacaoId: value?.id || null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="date"
              fullWidth
              required
              value={newEntry.data}
              onChange={(e) =>
                setNewEntry({ ...newEntry, data: e.target.value })
              }
            />
          </Grid>
        </Grid>

        {newEntry.registros.map((entry, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Grid item xs={3}>
              <Autocomplete
                options={peopleData?.data || []}
                getOptionLabel={(option) => option.name || ""}
                onChange={(_, newValue) =>
                  handleRowChange(index, "pessoaId", newValue?.id || "")
                }
                onInputChange={(_, newInputValue) =>
                  setSearchFilter(newInputValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Person")}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <OfferingInput
                label="Amount"
                value={entry.valor}
                onChange={(value) => handleRowChange(index, "valor", value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                value={entry.tipo}
                onChange={(e) => handleRowChange(index, "tipo", e.target.value)}
                fullWidth
              >
                {donationTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {t(type)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("Note")}
                value={entry.note}
                onChange={(e) => handleRowChange(index, "note", e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              {index > 0 && (
                <IconButton
                  onClick={() => handleDeleteRow(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Button startIcon={<AddIcon />} onClick={handleAddRow}>
          {t("Add Extra Records")}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate("/treasury/donation")}>
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          variant="contained"
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
