import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import {
  useLoggedUserQuery,
  useLoggedUserData,
  ensureLoggedUserQueryData,
} from "data/queries/queryLoggedUser";

function fetchCongregations(isAuthenticated, hasToFinishRegistration) {
  const usePrivateAPI = isAuthenticated && !hasToFinishRegistration;
  const endpoint = usePrivateAPI
    ? "/v1/congregacao"
    : "/v1/congregacao-publica";

  return fetch(endpoint, {
    method: "GET",
    skipAuth: !usePrivateAPI,
  }).then((r) => r.data);
}

const queryKey = ["congregations"];

export function useCongregationsQuery() {
  const { data: user, isLoading } = useLoggedUserQuery();
  const loggedUser = useLoggedUserData();

  const hasToFinishRegistration = loggedUser?.requiredActions?.some(
    (x) => x === "FINISH_REGISTRATION"
  );

  const queryFn = () => fetchCongregations(!!user, hasToFinishRegistration);

  return useQuery({
    queryKey,
    queryFn,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !isLoading,
  });
}

export async function ensureCongregationQueryData() {
  const loggedUser = await ensureLoggedUserQueryData();
  const isAuthenticated = !!loggedUser;
  const hasToFinishRegistration = loggedUser?.requiredActions?.some(
    (x) => x === "FINISH_REGISTRATION"
  );
  const queryFn = () =>
    fetchCongregations(isAuthenticated, hasToFinishRegistration);

  return queryClient.ensureQueryData(queryKey, queryFn);
}

export function setCongregationQueryData(data) {
  return queryClient.setQueryData(queryKey, data);
}

export function invalidateCongregationQuery() {
  return queryClient.invalidateQueries(queryKey);
}
