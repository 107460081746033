import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import {
  ensurePersonQuery,
  invalidatePersonQuery,
  usePersonQuery,
} from "data/queries/queryPerson";
import { invalidatePeopleQuery } from "data/queries/queryPeople";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import fetch from "data/fetch";
import MemberForm from "./components/MemberForm.react";
import MembersUpdateHeader from "./components/MembersUpdateHeader.react";

export async function loader({ params: { id } }) {
  await ensurePersonQuery(id);
  await ensureCongregationQueryData();
  return null;
}

export default function MemberUpdatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: queryData } = usePersonQuery({ id });
  const member = queryData?.data;

  // TODO move to mutations
  const onSubmit = async ({
    address,
    birthDate,
    churchDepartment,
    congregationId,
    creationStatus,
    documents: documentsForm,
    email,
    familyMembers,
    gender,
    hasPhotoChanged,
    maritalStatus,
    marriageAt,
    memberStatus,
    memberType,
    name,
    originatingChurch,
    phone,
    photo,
    photoFileId: photoFileIdForm,
    prefContact,
    roles,
    spiritBaptismAt,
    waterBaptismAt,
  }) => {
    let photoFileId = photoFileIdForm;

    if (hasPhotoChanged) {
      photoFileId = null;
      if (photo) {
        const photoData = new FormData();
        photoData.append("file", photo);
        photoData.append("name", photo.name);
        photoData.append("mime", photo.type);

        const photoResult = await fetch("/v1/file", {
          method: "POST",
          body: photoData,
        });

        photoFileId = photoResult.id;
      }
    }

    let documentFileIds = [];

    if (documentsForm != null) {
      for (const doc of documentsForm) {
        if (!doc.file) {
          continue;
        }

        if (doc.id != null && doc.file.id != null) {
          documentFileIds.push({
            fileId: doc.file.id,
            type: doc.type,
          });
        } else {
          const docData = new FormData();
          docData.append("file", doc.file);
          docData.append("name", doc.file.name);
          docData.append("mime", doc.file.type);

          const docResponse = await fetch(`/v1/file`, {
            method: "POST",
            body: docData,
          });

          documentFileIds.push({
            fileId: docResponse.id,
            type: doc.type,
          });
        }
      }
    }

    const documents = [
      photoFileId
        ? {
            fileId: photoFileId,
            type: "OFFICIAL_PHOTO",
          }
        : null,
    ]
      .concat(documentFileIds)
      .filter(Boolean);

    await fetch(`/v1/pessoa`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: address,
        birthDate,
        congregacaoId: congregationId,
        creationStatus,
        departments: churchDepartment,
        documents,
        email,
        familyMembers,
        gender,
        id,
        maritalStatus,
        marriageAt,
        name,
        originatingChurch,
        phone,
        prefContact,
        roles: roles,
        spiritBaptismAt,
        status: memberStatus,
        tipo: memberType,
        waterBaptismAt,
      }),
    });

    invalidatePeopleQuery();
    invalidatePersonQuery(id);
    navigate(`/members/${id}`);
  };

  return (
    <MemberForm
      key={member?.id}
      onSubmit={onSubmit}
      member={member}
      breadcrumbs={
        <MembersUpdateHeader
          heading={t("Members")}
          label={member?.name ?? "..."}
          routeIndex="/members"
          routeView={`/members/${id}`}
        />
      }
    />
  );
}
