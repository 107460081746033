import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Alert,
  LinearProgress,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { useLoggedUserData } from "data/queries/queryLoggedUser";
import { Link as RouterLink } from "react-router-dom";

export default function HomePage() {
  const { t } = useTranslation();
  const loggedUser = useLoggedUserData();

  // Profile completion tracking
  const profileFields = [
    { name: t("Name"), isComplete: !!loggedUser?.name },
    { name: t("Email"), isComplete: !!loggedUser?.email },
    { name: t("Phone"), isComplete: !!loggedUser?.phone },
    { name: t("Address"), isComplete: !!loggedUser?.address },
    { name: t("Profile Picture"), isComplete: !!loggedUser?.profilePicture },
  ];
  const completedFields = profileFields.filter((field) => field.isComplete).length;
  const totalXP = profileFields.length * 15;
  const earnedXP = completedFields * 15;
  const progress = (earnedXP / totalXP) * 100;

  // Define shortcut cards
  const shortcuts = [
    {
      title: t("Família Cristã"),
      link: "/familia-crista/list",
      description: t("Access Família Cristã resources."),
      bgColor: "#f5f5f5",
    },
    {
      title: t("Caring Groups"),
      link: "/caring_groups",
      description: t("Manage and join caring groups."),
      bgColor: "#e3f2fd",
    },
    {
      title: t("File of the Week"),
      link: "/familia-crista/files",
      description: t("Download the file of the week."),
      bgColor: "#e8f5e9",
    },
    {
      title: t("Profile"),
      link: "/profile",
      description: t("Complete your profile."),
      bgColor: "#fff8e1",
    },
  ];

  return (
    <Box sx={styles.container}>
      {/* Welcome Message */}
      <Box sx={styles.welcomeBox}>
        <Typography variant="h4" sx={styles.welcomeText}>
          {t("Welcome back")}, {loggedUser?.name || t("User")}!
        </Typography>
        <Typography variant="subtitle1" sx={styles.subText}>
          {t("Here are your quick actions and reminders.")}
        </Typography>
      </Box>

      {/* Profile Completion Section */}
      <Box sx={styles.reminderBox}>
        <Alert severity="info" sx={{ mb: 2 }}>
          {t("Don't forget to finish setting up your profile!")}
        </Alert>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t("Profile Completion:")} {earnedXP}/{totalXP} XP
        </Typography>
        <LinearProgress variant="determinate" value={progress} sx={styles.progressBar} />
        <Button
          component={RouterLink}
          to="/profile"
          variant="outlined"
          sx={{ mt: 1, textTransform: "none" }}
        >
          {t("Go to Profile")}
        </Button>
      </Box>

      {/* Shortcut Cards */}
      <Box sx={styles.shortcutBox}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t("Quick Access")}
        </Typography>
        <Grid container spacing={2}>
          {shortcuts.map((shortcut, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ ...styles.card, backgroundColor: shortcut.bgColor }}>
                <CardContent sx={styles.cardContent}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {shortcut.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {shortcut.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={shortcut.link}
                    size="small"
                  >
                    {t("Go")}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    padding: 3,
  },
  welcomeBox: {
    marginBottom: 4,
  },
  welcomeText: {
    fontWeight: "bold",
  },
  subText: {
    color: "textSecondary",
  },
  reminderBox: {
    marginBottom: 4,
  },
  progressBar: {
    height: 10,
    borderRadius: 5,
  },
  shortcutBox: {
    marginTop: 2,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: 3,
    borderRadius: 2,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
};
