import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { invalidatePeopleQuery } from "data/queries/queryPeople";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import fetch from "data/fetch";
import MemberForm from "./components/MemberForm.react";

export async function loader() {
  // preload congregations
  await ensureCongregationQueryData();
  return null;
}

export async function action() {
  // TODO move form submit to action
}

export default function MemberNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO move to mutations
  const onSubmit = async ({
    address,
    birthDate,
    churchDepartment,
    congregationId,
    creationStatus,
    documents: documentsForm,
    email,
    familyMembers,
    gender,
    hasPhotoChanged,
    maritalStatus,
    marriageAt,
    memberStatus,
    memberType,
    name,
    originatingChurch,
    phone,
    photo,
    photoFileId: photoFileIdForm,
    prefContact,
    roles,
    spiritBaptismAt,
    waterBaptismAt,
  }) => {
    let photoFileId = photoFileIdForm;

    if (hasPhotoChanged) {
      photoFileId = null;
      if (photo) {
        const photoData = new FormData();
        photoData.append("file", photo);
        photoData.append("name", photo.name);
        photoData.append("mime", photo.type);

        const photoResponse = await fetch(`/v1/file`, {
          method: "POST",
          body: photoData,
        });

        photoFileId = photoResponse.id;
      }
    }

    let documentFileIds = [];

    if (documentsForm) {
      for (const doc of documentsForm) {
        if (!doc.file) {
          continue;
        }

        const docData = new FormData();
        docData.append("file", doc.file);
        docData.append("name", doc.file.name);
        docData.append("mime", doc.file.type);

        const docResponse = await fetch(`/v1/file`, {
          method: "POST",
          body: docData,
        });

        documentFileIds.push({
          fileId: docResponse.id,
          type: doc.type,
        });
      }
    }

    const documents = [
      photoFileId
        ? {
            fileId: photoFileId,
            type: "OFFICIAL_PHOTO",
          }
        : null,
    ]
      .concat(documentFileIds)
      .filter(Boolean);

    await fetch(`/v1/pessoa`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: address,
        birthDate,
        congregacaoId: congregationId,
        creationStatus,
        departments: churchDepartment,
        documents,
        email,
        familyMembers,
        gender,
        maritalStatus,
        marriageAt,
        name,
        originatingChurch,
        phone,
        prefContact,
        roles: roles,
        spiritBaptismAt,
        status: memberStatus,
        tipo: memberType,
        waterBaptismAt,
      }),
    });

    invalidatePeopleQuery();
    navigate("/members");
  };

  return (
    <MemberForm
      onSubmit={onSubmit}
      member={false}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new member")}
          links={[
            {
              name: t("Members"),
              to: "/members",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
