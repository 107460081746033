import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Summarize from "@mui/icons-material/Summarize";
import IconButton from "@mui/material/IconButton";
import BadgeIcon from "@mui/icons-material/Badge";
import MenuBook from "@mui/icons-material/MenuBook";
import SettingsIcon from "@mui/icons-material/Settings";
import ChurchIcon from "@mui/icons-material/Church";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"; // Choose an icon
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import LogoWordmark from "shared/components/Logo/LogoWordmark.react";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import ViewportSideNavItem from "./ViewportSideNavItem.react";
import { useTranslation } from "react-i18next";
import { useIsDesktop } from "shared/hooks/useResponsive";
import usePermissions from "shared/hooks/usePermissions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ViewportSideNavSubItem from "./ViewportSideNavSubItem.react";
import ViewportSideNavContext from "./ViewportSideNavContext.react";
import config from 'config/config';

export const DRAWER_WIDTH = 280;
export const DRAWER_WIDTH_COLLAPSED = 88;

export default function ViewportSideNav({ isOpen, toggleDrawer }) {
  const { t } = useTranslation();
  const hasPermission = usePermissions();

  return (
    <ViewportSideNavContext.Provider value={isOpen}>
      <Box component="nav" sx={[styles.root, !isOpen && styles.rootCollapsed]}>
        <NavDrawer isOpen={isOpen} toggleDrawer={toggleDrawer}>
          <NavButton onClick={toggleDrawer} isOpen={isOpen} />
          <Stack sx={[styles.stack, !isOpen && styles.stackCollapsed]}>
            <Link component={RouterLink} to="/" sx={styles.logoLink}>
              <LogoWordmark sx={[styles.logo, !isOpen && styles.logoHidden]} />
              <LogoWordmark
                logomark={true}
                sx={[styles.logo, isOpen && styles.logoHidden]}
              />
            </Link>
            <Box sx={styles.scrollable}>
              <List component="div" disablePadding sx={{ px: isOpen ? 2 : 0 }}>
                <ViewportSideNavItem
                  data-testid="main-menu-item/my-account"
                  to="/profile"
                  label={t("My Account")}
                  icon={<AccountCircleIcon />}
                />
                {hasPermission("read", "convidado-visita") ? (
                  <>
                    <ViewportSideNavItem
                      to="/guests"
                      label={t("Convidados")}
                      icon={<GroupAddIcon />}
                    >
                      <ViewportSideNavSubItem
                        to="/guests/list"
                        label={t("List")}
                      />
                      <ViewportSideNavSubItem
                        to="/guests/new"
                        label={t("Add Guests")}
                      />
                    </ViewportSideNavItem>
                  </>
                ) : (
                  config.env !== 'confradeb-prod' ? (
                    <ViewportSideNavItem
                      to="/guests/new"
                      label={t("Add Guests")}
                      icon={<GroupAddIcon />}
                    />
                  ) : null
                )}
                {hasPermission("read", "friends-4ever-relatorio") && (
                  <ViewportSideNavItem
                    to="/friends-4ever"
                    label={t("Friends4Ever")}
                    icon={<PeopleAltIcon />}
                  >
                    <ViewportSideNavSubItem
                      to="/friends-4ever"
                      label={t("List")}
                    />
                    <ViewportSideNavSubItem
                      to="/friends-4ever/analytics"
                      label={t("Analytics")}
                    />
                  </ViewportSideNavItem>
                )}
                {hasPermission("read", "relatorio-culto-secretaria") && (
                  <ViewportSideNavItem
                    to="/culto-secretaria"
                    label={t("Service Secretary")}
                    icon={<Summarize />}
                  >
                    <ViewportSideNavSubItem
                      to="/culto-secretaria"
                      label={t("List")}
                    />
                    <ViewportSideNavSubItem
                      to="/culto-secretaria/analytics"
                      label={t("Analytics")}
                    />
                  </ViewportSideNavItem>
                )}

                {hasPermission("read", "relatorio-culto-tesouraria") && (
                  <ViewportSideNavItem
                    to="/treasury"
                    label={t("Treasury")}
                    icon={<AttachMoneyIcon />}
                  >
                    <ViewportSideNavSubItem
                      to="/treasury"
                      label={t("List")}
                    />
                    {hasPermission("read", "doacoes") && (
                      <ViewportSideNavSubItem
                        to="/treasury/donation"
                        label={t("Donation")}
                      />
                    )}
                    <ViewportSideNavSubItem
                      to="/treasury/analytics"
                      label={t("Analytics")}
                    />
                  </ViewportSideNavItem>
                )}
                {hasPermission("read", "caring-groups") && (
                  <ViewportSideNavItem
                    to="/caring_groups"
                    label={t("Caring Groups")}
                    icon={<HowToRegIcon />}
                  >
                    <ViewportSideNavSubItem
                      to="/caring_groups"
                      label={t("List")}
                    />
                    <ViewportSideNavSubItem
                      to="/caring_groups_reports"
                      label={t("Reports")}
                    />
                    <ViewportSideNavSubItem
                      to="/caring_groups/analytics"
                      label={t("Analytics")}
                    />
                  </ViewportSideNavItem>
                )}
                {hasPermission("read", "familia-crista") && (
                  <ViewportSideNavItem
                    to="/familia-crista/list"
                    label={t("Christian Family")}
                    icon={<MenuBook />}
                  >
                    <ViewportSideNavSubItem
                      to="/familia-crista/list"
                      label={t("List")}
                    />
                    <ViewportSideNavSubItem
                      to="/familia-crista/reports"
                      label={t("Reports")}
                    />
                    <ViewportSideNavSubItem
                      to="/familia-crista/analytics"
                      label={t("Analytics")}
                    />
                    <ViewportSideNavSubItem
                      to="/familia-crista/files"
                      label={t("File of the Week")}
                    />
                    <ViewportSideNavSubItem
                      to="/familia-crista/upload-file"
                      label={t("Upload File")}
                    />
                  </ViewportSideNavItem>
                )}
                {hasPermission("read", "pessoa") && (
                  <ViewportSideNavItem
                    to="/members"
                    label={t("Members")}
                    icon={<BadgeIcon />}
                  >
                    <ViewportSideNavSubItem to="/members" label={t("List")} />
                    <ViewportSideNavSubItem
                      to="/members/analytics"
                      label={t("Analytics")}
                    />
                  </ViewportSideNavItem>
                )}
                {(hasPermission("read", "regional") ||
                  hasPermission("read", "congregacao")) && (
                  <ViewportSideNavItem
                    to=""
                    label={t("Regional / Congregation")}
                    icon={<ChurchIcon />}
                  >
                    {hasPermission("read", "congregacao") && (
                      <ViewportSideNavSubItem
                        to="/congregation/list"
                        label={t("Congregation")}
                      />
                    )}
                    {hasPermission("read", "regional") && (
                      <ViewportSideNavSubItem
                        to="/regional/list"
                        label={t("Regional")}
                      />
                    )}
                  </ViewportSideNavItem>
                )}

                {hasPermission("read", "system-permission") &&
                  hasPermission("read", "system-role") && (
                    <ViewportSideNavItem
                      data-testid="system-settings"
                      to="/system-settings"
                      label={t("System Settings")}
                      icon={<SettingsIcon />}
                    >
                      <ViewportSideNavSubItem
                        to="/system-settings/permissions"
                        label={t("Permissions")}
                      />
                    </ViewportSideNavItem>
                  )}
              </List>
            </Box>
          </Stack>
        </NavDrawer>
      </Box>
    </ViewportSideNavContext.Provider>
  );
}

function NavDrawer({ children, isOpen, toggleDrawer }) {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <Drawer
      open={true}
      variant="permanent"
      PaperProps={{
        sx: [styles.drawerDesktop, !isOpen && styles.rootCollapsed],
      }}
    >
      {children}
    </Drawer>
  ) : (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: styles.drawerMobile,
      }}
    >
      {children}
    </Drawer>
  );
}

function NavButton({ onClick, isOpen }) {
  const theme = useTheme();

  return (
    <IconButton
      size="small"
      sx={[styles.expandBtn(theme), !isOpen && styles.expandBtnCollapsed]}
      onClick={onClick}
    >
      {isOpen ? (
        <ChevronLeftIcon fontSize="inherit" />
      ) : (
        <ChevronRightIcon fontSize="inherit" />
      )}
    </IconButton>
  );
}

const styles = {
  root: {
    width: { lg: DRAWER_WIDTH },
    flexShrink: { lg: 0 },
  },
  rootCollapsed: {
    width: { lg: DRAWER_WIDTH_COLLAPSED },
  },
  drawerDesktop: {
    width: DRAWER_WIDTH,
    bgcolor: "background.default",
    borderRight: 1,
    borderColor: "divider",
  },
  drawerMobile: {
    width: DRAWER_WIDTH,
  },
  expandBtn: (theme) => ({
    width: 28,
    height: 28,
    top: 42,
    position: "fixed",
    left: DRAWER_WIDTH - 14,
    border: 1,
    borderColor: "divider",
    bgcolor: "background.default",
    "&:hover": {
      bgcolor: "background.default",
    },
    zIndex: theme.zIndex.appBar + 1,
  }),
  expandBtnCollapsed: {
    top: 22,
    left: DRAWER_WIDTH_COLLAPSED - 14,
  },
  stack: {
    height: 1,
    position: "fixed",
    width: DRAWER_WIDTH,
    overflow: "auto", // Add this line to enable scrolling
  },
  stackCollapsed: {
    pb: 2,
    width: DRAWER_WIDTH_COLLAPSED,
    overflow: "auto", // Add this line to enable scrolling
  },
  logoLink: {
    mt: 3,
    ml: 4,
    mb: 1,
  },
  logo: {
    flexGrow: 0,
    height: 50,
  },
  logoHidden: {
    display: "none",
  },
  scrollable: {
    overflowY: "auto", // Add this line to enable vertical scrolling
    flexGrow: 1,
  },
  sublistHeader: (theme) => ({
    ...theme.typography.overline,
    fontSize: 11,
    cursor: "pointer",
    display: "inline-flex",
    padding: "4px 8px 4px 12px",
    paddingTop: theme.spacing(2),
    marginBottom: "4px",
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.disabled,
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      color: theme.palette.text.primary,
    },
  }),
  sublistHeaderCollapsed: (theme) => ({
    padding: "6px 0 0 0",
  }),
};
