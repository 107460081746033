import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";

export default function FormCard({ title, children }) {
    return (
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Stack spacing={2}>{children}</Stack>
        </CardContent>
      </Card>
    );
  }
