import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchTreasury(id) {
  return fetch(`/v1/relatorio-culto-secretaria/${id}/tesouraria`);
}

const getQueryKey = (id) => ["treasury", id];

export function useTreasuryQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchTreasury(id),
  });
}

export function ensureTreasuryQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchTreasury(id),
  });
}

export function invalidateTreasuryQuery() {
  return queryClient.invalidateQueries(["treasury"]);
}
