import { invalidateCongregationsQuery } from "data/queries/congregation/queryCongregations";
import { useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import CongregationForm from "./components/CongregationForm.react";
import fetch from "data/fetch";

import { mutateCongregation } from "data/mutations/mutateCongregation";

export async function loader({ params: { id } }) {
  const { data: congregation } = await fetch(`/v1/congregacao/${id}`);

  return {
    congregationId: id,
    name: congregation.name,
    country: congregation.country,
    address: congregation.address,
    regionalId: congregation.regional.id,
    regionalName: congregation.regional.name,
    pastorId: congregation.pastor?.id,
    pastorName: congregation.pastor?.name,
    latitude: congregation.latitude,
    longitude: congregation.longitude,
    timeZoneId: congregation.timeZoneId ?? null, // Include timeZoneId in the loader data
  };
}

export default function CongregationEditPage() {
  const data = useLoaderData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      const success = await mutateCongregation({
        ...formData,
        congregationId: data.congregationId,
      });
      if (!success) {
        throw new Error("Error submitting the form");
      }
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }

    invalidateCongregationsQuery();
    navigate("/congregation/list");
  };

  const onDelete = async () => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return false;
    }

    try {
      await fetch(`/v1/congregacao/${data.congregationId}`, {
        method: "DELETE",
      });
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }

    invalidateCongregationsQuery();
    navigate("/congregation/list");
  };

  return (
    <CongregationForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit Congregation")}
          links={[
            {
              name: t("Congregations"),
              to: "/congregation/list",
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
