import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useFriends4EverQuery,
  ensureFriends4EverQueryData,
  invalidateFriends4EverQuery,
} from "data/queries/friends-4ever/queryFriends4Ever";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import fetch from "data/fetch";
import { useCallback } from "react";

export async function loader() {
  return await ensureFriends4EverQueryData();
}

export default function Friends4EverIndexPage() {
  const { t } = useTranslation();

  const handleDelete = useCallback(async (id) => {
    if (window.confirm(t("Are you sure you want to delete this entry?"))) {
      try {
        await fetch(`/v1/friends-4ver/${id}`, { method: "DELETE" });
        invalidateFriends4EverQuery();
      } catch (error) {
        alert(t("Failed to delete the entry. Please try again."));
      }
    }
  }, [t]);

  return (
    <IndexPageLayout
      useQuery={useFriends4EverQuery}
      hasSearch={false}
      header={
        <IndexPageHeader
          heading={t("Friends4Ever")}
          routeIndex="/friends-4ever"
          routeNew="/friends-4ever/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            t("Congregation"),
            t("Date"),
            t("Guests (Non-Believers)"),
            t("Accepted/Returned"),
            t("Actions"),
          ]}
          renderRow={({
            id,
            congregacao,
            data,
            convidadosNaoCrentes,
            aceitaramVoltaram,
          }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {congregacao?.name ?? t("Unknown Congregation")}
                </Typography>
              </TableCell>
              <TableCell>
                <Link component={RouterLink} to={`/friends-4ever/${id}`}>
                  {data}
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {convidadosNaoCrentes}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {aceitaramVoltaram}
                </Typography>
              </TableCell>
              <TableCell>
                <IconButton
                  edge="end"
                  aria-label={t("Delete")}
                  onClick={() => handleDelete(id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
