import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import fetch from "data/fetch";


export async function featureLoader() {
  const { data: feature } = await fetch(`/v1/feature`);
  return {
    feature: feature,
  };
}

export default function ViewportDonationButton() {
  const { t } = useTranslation();
  const [donationBtnVisible, setDonationBtnVisible] = useState(false);

  useEffect(() => {
      async function fetchData() {
        const { feature } = await featureLoader();
        setDonationBtnVisible(feature.donation);
      };
      fetchData();
  },[]);

  return (
    <>
    {donationBtnVisible && (
      <Link to="/pre-donation">
        <Button variant="contained" color="primary">{t("Donate")}</Button>
      </Link>
    )}
    </>
    
    
  )
}
