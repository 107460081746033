import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import FormCard from "shared/components/FormCard/FormCard.react";
import MenuItem from "@mui/material/MenuItem";
import RemoveIcon from "@mui/icons-material/Remove";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import Button from "@mui/material/Button";
import SelectField from "shared/components/SelectField/SelectField";

const PHOTO_ALLOWED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
const DOCUMENT_ALLOWED_FORMATS = [...PHOTO_ALLOWED_FORMATS, "application/pdf"];

export const DocumentType = {
  OFFICIAL_PHOTO: "OFFICIAL_PHOTO",
  OFFICIAL_DOCUMENT: "OFFICIAL_DOCUMENT",
  MARRIAGE_DOCUMENT: "MARRIAGE_DOCUMENT",
  COURSE_CERTIFICATE: "COURSE_CERTIFICATE",
  THEOLOGY_CERTIFICATE: "THEOLOGY_CERTIFICATE",
  TRANSFERRED_LETTER: "TRANSFERRED_LETTER",
  OTHER: "OTHER",
};

const MemberFormDocumentsInfoCard = forwardRef(
  function MemberFormDocumentsInfoCard(
    {
      documents,
      formErrors,
      onAddDocumentClick,
      onDocumentChange,
      onRemoveDocumentClick,
    },
    ref
  ) {
    const { t } = useTranslation();

    useImperativeHandle(
      ref,
      () => ({
        validate(formErrors) {
          documents?.forEach((document, i) => {
            if (document.removed === true) {
              return;
            }

            if (document.file != null && isStringNullOrEmpty(document.type)) {
              formErrors.documents = formErrors.documents || {};
              formErrors.documents[i] = t("Document type is required");
            }
          });
        },
      }),
      [documents, t]
    );

    return (
      <FormCard title="Documentos Adicionais">
        <Typography variant="subtitle1" gutterBottom>
          Certificados de conclusão de curso, Diplomas de Faculdade em Teologia,
          Carta de Mudança ou Transferência, e entre outros.
        </Typography>

        {documents?.map((doc, index) => (
          <Stack
            direction="row"
            spacing={1}
            key={doc.id ?? doc.clientId}
            sx={{ flex: 1, display: doc.removed ? "none" : "flex" }}
            alignItems="center"
          >
            <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
              <TextField
                sx={{ flex: 1 }}
                InputLabelProps={{ shrink: true }}
                accept="image/*"
                label={t("Document")}
                type={doc.id ? "text" : "file"}
                disabled={doc.id != null}
                onChange={
                  doc.id
                    ? null
                    : (e) => {
                        const input = e.target;
                        let file = input.files[0];

                        // todo: move to formErrors
                        if (
                          file != null &&
                          !DOCUMENT_ALLOWED_FORMATS.includes(file.type)
                        ) {
                          input.value = "";
                          file = null;

                          alert(t("Document file format is invalid."));
                        }

                        onDocumentChange({ ...doc, file }, index);
                      }
                }
                readonly={doc.id != null}
                value={doc.id != null ? doc?.file?.name : undefined}
              />

              <SelectField
                sx={{ flex: 1 }}
                label={t("Type")}
                value={doc.type}
                onChange={(type) => onDocumentChange({ ...doc, type }, index)}
                error={!!formErrors?.documents?.[index]}
                helperText={formErrors?.documents?.[index]}
                required={doc.file != null}
              >
                <MenuItem value={DocumentType.OFFICIAL_DOCUMENT}>
                  {t("Document Pessoal")}
                </MenuItem>
                <MenuItem value={DocumentType.MARRIAGE_DOCUMENT}>
                  {t("Certidão de Casamento")}
                </MenuItem>
                <MenuItem value={DocumentType.THEOLOGY_CERTIFICATE}>
                  {t("Certificado de Curso (Teologia)")}
                </MenuItem>
                <MenuItem value={DocumentType.COURSE_CERTIFICATE}>
                  {t("Certificado de Curso (outros)")}
                </MenuItem>
                <MenuItem value={DocumentType.TRANSFERRED_LETTER}>
                  {t("Carta de Transferência")}
                </MenuItem>
                <MenuItem value={DocumentType.OTHER}>{t("Outro")}</MenuItem>
              </SelectField>

              <Button
                startIcon={<RemoveIcon />}
                variant="contained"
                size="medium"
                onClick={() => onRemoveDocumentClick(index)}
              >
                {t("Remove Document")}
              </Button>
            </Stack>
          </Stack>
        ))}
        <Button
          onClick={onAddDocumentClick}
          startIcon={<AddIcon />}
          variant="outlined"
        >
          {t("Add Document")}
        </Button>
      </FormCard>
    );
  }
);

export default MemberFormDocumentsInfoCard;
