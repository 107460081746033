import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { captureException } from "@sentry/react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

/**
 * Unify way to display errors in an alert box. Useful for forms.
 *
 *  - [error=null]: no error, renders nothing
 *  - [error=string]: a crafted message, consider the error as handled
 *  - [error=Error]: an instance of Error class is considered unhandled error.
 *
 */
export default function ErrorAlert({ error }) {
  const { t } = useTranslation();
  const [sentryId, setSentryId] = useState(null);
  const isErrorMessage = error !== "" && typeof error === "string";

  useEffect(() => {
    if (error === "" || error == null || isErrorMessage) {
      return;
    }

    console.error(error);
    const sentryId = captureException(error, { level: "fatal" });
    setSentryId(sentryId);
  }, [isErrorMessage, error]);

  if (error == null || error === "") {
    return null;
  }

  // handled error, show the message
  if (isErrorMessage) {
    return <Alert severity="error">{error}</Alert>;
  }

  // unhandled error, needs logging
  return (
    <Alert severity="error">
      <AlertTitle>{t("Sorry, an unexpected error has occurred.")}</AlertTitle>
      <Box component="pre" sx={styles.code}>
        <b>{t("Technical Information")}</b>
        {"\n"}
        Sentry ID: {sentryId}
        {error.apiMessage != null && (
          <>
            {"\n"}
            {error.apiMessage}
          </>
        )}
        {"\n"}
        {error.stack}
      </Box>
    </Alert>
  );
}

const styles = {
  code: {
    // bgcolor: "grey.100",
    // border: 1,
    // borderColor: "grey.200",
    fontSize: 11,
    // maxWidth: "100%",
    // padding: 1,
    whiteSpace: "pre-wrap",
  },
};
