import { useEffect, useState } from "react";
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { captureException } from "@sentry/react";
import Box from "@mui/material/Box";

export default function RootGlobalError() {
  const { t } = useTranslation();
  const error = useRouteError();
  const knownErrorMessage = useKnownErrorMessage(error);
  const [sentryId, setSentryId] = useState(null);

  useEffect(() => {
    console.error(error);
    if (error.status === 404) {
      console.log("404: Page not found");
    } else {
      const sentryId = captureException(error, { level: "fatal" });
      setSentryId(sentryId);
    }
  }, [error]);

  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={styles.root}
    >
      <Typography variant="h1">{t("Oops!")}</Typography>
      {knownErrorMessage ? (
        <Typography variant="body1">{knownErrorMessage}</Typography>
      ) : (
        <>
          <Typography variant="subtitle1">
            {t("Sorry, an unexpected error has occurred.")}
          </Typography>

          <Box component="pre" sx={styles.code}>
            <b>{t("Technical Information")}</b>
            {"\n"}
            Sentry ID: {sentryId}
            {"\n"}
            {error.stack}
          </Box>
        </>
      )}
    </Stack>
  );
}

const styles = {
  root: {
    height: "100%",
    padding: "16px",
  },
  code: {
    bgcolor: "grey.100",
    border: 1,
    borderColor: "grey.200",
    fontSize: 11,
    maxWidth: "100%",
    padding: 1,
    whiteSpace: "pre-wrap",
  },
};

function useKnownErrorMessage(error) {
  const { t } = useTranslation();

  switch (error.status) {
    case 404:
      return t("Page not found.");
    default:
      return null;
  }
}
