import { invalidateGuestsQuery } from "data/queries/queryGuests";
import { useMutation } from "@tanstack/react-query";
import fetch from "data/fetch";

export function useGuestsDeleteMutation({ onSuccess } = {}) {
  return useMutation({
    mutationFn(id) {
      return fetch(`/v1/convidado-visita/${id}`, { method: "DELETE" });
    },
    onSuccess() {
      invalidateGuestsQuery();
      onSuccess?.();
    },
  });
}
