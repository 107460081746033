import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";
import queryClient from "data/queryClient";

function fetchPerson(id) {
  return fetch(`/v1/pessoa/${id}`);
}

const getQueryKey = (id) => ["person", id];

export function usePersonQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchPerson(id),
  });
}

export function ensurePersonQuery(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchPerson(id),
  });
}

export function invalidatePersonQuery(id) {
  return queryClient.invalidateQueries(["person", id]);
}
