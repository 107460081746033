import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormCard from "shared/components/FormCard/FormCard.react";
import DonationTypeSelect from "shared/components/DonationTypeSelect/DonationTypeSelect.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";

export default function PreDonationForm({
  breadcrumbs,
  onSubmit: onSubmitProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [note, setNote] = useState(null);
  const [type, setType] = useState(null);
  const actionData = useActionData();

  const onSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    onSubmitProp({
      type,
      note,
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Container component="form" method="post" onSubmit={onSubmit}>
      {breadcrumbs}
      <Stack spacing={3} sx={{ p: 3 }}>
        <FormCard>
          <DonationTypeSelect
            required
            value={type}
            onChange={(e) => setType((e.currentTarget ?? e.target).value)}
          />
          <TextField
            label={t("Note")}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />

          <ErrorAlert error={errorProp} />
          <ErrorAlert error={actionData?.error} />

          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </FormCard>
      </Stack>
    </Container>
  );
}
