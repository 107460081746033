import { redirect } from "react-router-dom";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import { getAuthConfig } from "config/auth-config";

const {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
} = getAuthConfig();

const REDIRECT_PATHNAME = "/login-callback";
const REDIRECT_URI = `${window.location.origin}${REDIRECT_PATHNAME}`;
const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

let $user;
let $authClientPromise;

/**
 * Used with react-router to block unauthorized users and redirect to the login
 * page.
 */
export function protectedLoaderFactory(routeLoader) {
  return async function authLoader(params) {
    if (await _isAuthSessionValid()) {
      return routeLoader?.(params) ?? null;
    }

    return redirect(_getLoginPageURL());
  };
}

/**
 * Loader for the /login route, redirecting user to the main experience if
 * already logged in.
 */
export async function loginRouteLoader() {
  return (await _isAuthSessionValid()) ? redirect("/") : null;
}

/**
 * Loader for the /login-callback route, continuing the login flow.
 */
export async function loginCallbackRouteLoader() {
  const auth0Client = await _initAuth0Client();
  const searchParams = window.location.search;
  const hasAuthParams =
    (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
    STATE_RE.test(searchParams);

  let returnTo;

  if (hasAuthParams) {
    const result = await auth0Client.handleRedirectCallback();
    returnTo = result?.appState?.returnTo;
  }

  return redirect(returnTo ?? "/");
}

/**
 * Starts the login flow. Should be connected to a "login" button.
 */
export async function login(returnTo) {
  const auth0Client = await _initAuth0Client();

  return await auth0Client.loginWithRedirect({
    appState: { returnTo },
    authorizationParams: {
      audience: AUTH0_AUDIENCE,
      redirect_uri: REDIRECT_URI,
    },
  });
}

/**
 * Starts the logout flow. Should be connected to a "logout" button.
 */
export function logout() {
  if ($authClientPromise != null) {
    void Promise.resolve($authClientPromise).then((auth0Client) =>
      auth0Client.logout()
    );
  }
}

/**
 * Gives access to static user data. This will only be valid after log in and
 * might be null during app initialization.
 */
export function getUser() {
  return $user;
}

/**
 * Return auth0 access token, cached from memory, or fetch a new one if it's
 * expired. Might be null if token refresh exceeded the configured time limit.
 */
export async function getValidAccessTokenOrRedirect() {
  let accessToken = null;

  try {
    const auth0Client = await _initAuth0Client();
    accessToken = await auth0Client.getTokenSilently();
  } catch {}

  if (accessToken == null) {
    window.location.href = _getLoginPageURL();
  }

  return accessToken;
}

/**
 * Returns or initializes the singleton auth0client instance.
 */
async function _initAuth0Client() {
  if (!$authClientPromise) {
    $authClientPromise = (async function init() {
      const auth0Client = await createAuth0Client({
        domain: AUTH0_DOMAIN,
        clientId: AUTH0_CLIENT_ID,
        cacheLocation: "localstorage",
        useRefreshTokens: true,
        authorizationParams: {
          audience: AUTH0_AUDIENCE,
        },
      });
      $user = await auth0Client.getUser();

      return auth0Client;
    })();
  }

  return await $authClientPromise;
}

/**
 * Return true if the user is authenticated and the session is valid. Otherwise,
 * clear everything with logout and send them back to login page.
 */
async function _isAuthSessionValid() {
  const auth0Client = await _initAuth0Client();

  try {
    const isAuthenticated = await auth0Client.isAuthenticated();
    const accessToken = await auth0Client.getTokenSilently();

    return isAuthenticated === true && accessToken != null;
  } catch {
    void auth0Client.logout({ openUrl: false }); // clear any bad state (i.e. expired token stored)
    return false;
  }
}

/**
 * Return the login URL with a return to the current one.
 */
function _getLoginPageURL() {
  const { pathname, search, hash } = window.location;
  const returnTo = `${pathname}${search}${hash}`;

  return returnTo === "/"
    ? "/login"
    : `/login?returnTo=${encodeURIComponent(returnTo)}`;
}
