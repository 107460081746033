import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { usePublicPeopleQuery } from "data/queries/queryPublicPeople";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";


const OfferingInput = ({ label, value, onChange }) => {
  const { t } = useTranslation();
  return (
    <TextField
      label={t(label)}
      type="text"
      fullWidth
      value={value}
      onChange={(e) => {
        let inputValue = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
          onChange(inputValue);
        }
      }}
      onFocus={(e) => e.target.select()}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      }}
      onWheel={(e) => e.target.blur()}
      sx={{ mb: 2 }}
    />
  );
};

export default function TreasuryForm({
  breadcrumbs,
  onSubmit: onSubmitProp,
  error: errorProp,
}) {
  const { id: relatorioCultoSecretariaId } = useParams(); // Get relatorioCultoSecretariaId from URL
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]); // Main array of people and offerings
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Dialog state for adding a new person
  const [newEntry, setNewEntry] = useState({
    pessoaId: "",
    pessoaName: "",
    oferta: 0,
    dizimo: 0,
    ofertaMissao: 0,
    ofertaEBD: 0,
    ofertaFC: 0,
    ofertaPrimicias: 0,
    ofertaOutras: 0,
  });

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchFilter, setSearchFilter] = useState(""); // Filter for dynamic search

  const { data: peopleData, isLoading } = usePublicPeopleQuery({
    filter: searchFilter,
  }); // Fetch people data dynamically

  const [validationError, setValidationError] = useState("");

  // Calculate totals for each column
  const totals = entries.reduce(
    (acc, entry) => {
      acc.oferta += parseFloat(entry.oferta || 0);
      acc.dizimo += parseFloat(entry.dizimo || 0);
      acc.ofertaMissao += parseFloat(entry.ofertaMissao || 0);
      acc.ofertaEBD += parseFloat(entry.ofertaEBD || 0);
      acc.ofertaFC += parseFloat(entry.ofertaFC || 0);
      acc.ofertaPrimicias += parseFloat(entry.ofertaPrimicias || 0);
      acc.ofertaOutras += parseFloat(entry.ofertaOutras || 0);
      acc.total +=
        parseFloat(entry.oferta || 0) +
        parseFloat(entry.dizimo || 0) +
        parseFloat(entry.ofertaMissao || 0) +
        parseFloat(entry.ofertaEBD || 0) +
        parseFloat(entry.ofertaFC || 0) +
        parseFloat(entry.ofertaPrimicias || 0) +
        parseFloat(entry.ofertaOutras || 0);
      return acc;
    },
    {
      oferta: 0,
      dizimo: 0,
      ofertaMissao: 0,
      ofertaEBD: 0,
      ofertaFC: 0,
      ofertaPrimicias: 0,
      ofertaOutras: 0,
      total: 0,
    }
  );

  // Calculate the overall subtotal
  const subtotal =
    totals.oferta +
    totals.dizimo +
    totals.ofertaMissao +
    totals.ofertaEBD +
    totals.ofertaFC +
    totals.ofertaPrimicias +
    totals.ofertaOutras;

  const handleAddEntry = () => {
    // Validate if a person is selected
    if (!newEntry.pessoaId) {
      setValidationError(t("Please select a person."));
      return;
    }

    setEntries([...entries, { ...newEntry }]); // Add new entry to entries array
    setNewEntry({
      pessoaId: "",
      pessoaName: "",
      oferta: 0,
      dizimo: 0,
      ofertaMissao: 0,
      ofertaEBD: 0,
      ofertaFC: 0,
      ofertaPrimicias: 0,
      ofertaOutras: 0,
    });
    setValidationError(""); // Clear any previous validation errors
    setIsDialogOpen(false);
  };

  const handleRemoveEntry = (index) => {
    setEntries(entries.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = entries.map((entry) => ({
      ...entry,
      relatorioCultoSecretariaId, // Add the ID from the URL to each entry
      pessoaId: entry.pessoaId === "anonymous" ? null : entry.pessoaId, // Replace "anonymous" with null
    }));

    onSubmitProp(payload).finally(() => {
      setIsSubmitting(false);
    });
  };

  const calculateSubtotal = () => {
    return (
      parseFloat(newEntry.oferta || 0) +
      parseFloat(newEntry.dizimo || 0) +
      parseFloat(newEntry.ofertaMissao || 0) +
      parseFloat(newEntry.ofertaEBD || 0) +
      parseFloat(newEntry.ofertaFC || 0) +
      parseFloat(newEntry.ofertaPrimicias || 0) +
      parseFloat(newEntry.ofertaOutras || 0)
    ).toFixed(2);
  };

  return (
    <Container>
      {breadcrumbs}
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          {/* Table displaying current entries */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Person")}</TableCell>
                  <TableCell>{t("Total")}</TableCell>
                  <TableCell>{t("Offering")}</TableCell>
                  <TableCell>{t("Tithe")}</TableCell>
                  <TableCell>{t("Mission Offering")}</TableCell>
                  <TableCell>{t("EBD Offering")}</TableCell>
                  <TableCell>{t("Christian Family Offering")}</TableCell>
                  <TableCell>{t("Firstfruits Offering")}</TableCell>
                  <TableCell>{t("Other Offerings")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {entry.pessoaId === "anonymous"
                        ? t("Anonymous Person")
                        : (entry.pessoaName ?? t("Unknown Person"))}
                    </TableCell>
                    <TableCell>
                      {(

                        parseFloat(entry.oferta || 0) +
                        parseFloat(entry.dizimo || 0) +
                        parseFloat(entry.ofertaMissao || 0) +
                        parseFloat(entry.ofertaEBD || 0) +
                        parseFloat(entry.ofertaFC || 0) +
                        parseFloat(entry.ofertaPrimicias || 0) +
                        parseFloat(entry.ofertaOutras || 0)
                      ).toFixed(2)}
                    </TableCell>
                    <TableCell>{entry.oferta}</TableCell>
                    <TableCell>{entry.dizimo}</TableCell>
                    <TableCell>{entry.ofertaMissao}</TableCell>
                    <TableCell>{entry.ofertaEBD}</TableCell>
                    <TableCell>{entry.ofertaFC}</TableCell>
                    <TableCell>{entry.ofertaPrimicias}</TableCell>
                    <TableCell>{entry.ofertaOutras}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveEntry(index)}
                      >
                        {t("Remove")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

                {/* Totals Row */}
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("Total")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{totals.total.toFixed(2)}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {totals.oferta.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {totals.dizimo.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {totals.ofertaMissao.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {totals.ofertaEBD.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {totals.ofertaFC.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {totals.ofertaPrimicias.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {totals.ofertaOutras.toFixed(2)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Subtotal Display */}
          <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
            {t("Subtotal")}: {subtotal.toFixed(2)}
          </Typography>

          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => setIsDialogOpen(true)}
            >
              {t("Add New Registry")}
            </Button>
          </Stack>

          <ErrorAlert error={errorProp} />

          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit}
            >
              {isSubmitting ? t("Submitting...") : t("Save")}
            </Button>
          </Stack>
        </Stack>
      </Card>

      {/* Dialog for adding a new entry */}
      <>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullScreen={!isLargeScreen}
        fullWidth
        maxWidth={isLargeScreen ? "lg" : "sm"}
      >
        <DialogTitle>{t("Add New Registry")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Row 1: Member Selection */}
            <Grid item sx={{ mt: 3}} xs={6}>
              <Autocomplete
                value={
                  newEntry.pessoaId
                    ? { id: newEntry.pessoaId, name: newEntry.pessoaName }
                    : null
                }
                options={[
                  { id: "anonymous", name: t("Anonymous Person") },
                  ...(peopleData?.data || []),
                ]}
                getOptionLabel={(option) => option.name || ""}
                onInputChange={(_, newInputValue) => setSearchFilter(newInputValue)}
                onChange={(_, newValue) => {
                  setNewEntry({
                    ...newEntry,
                    pessoaId: newValue?.id || "",
                    pessoaName: newValue?.name || "",
                  });
                }}
                loading={isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Person")}
                    fullWidth
                    required
                    error={!!validationError}
                    helperText={validationError}
                  />
                )}
              />
            </Grid>

            {/* Row 2: Offerings Inputs */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1.7}>
                  <OfferingInput
                    label="Offering"
                    value={newEntry.oferta}
                    onChange={(value) => setNewEntry({ ...newEntry, oferta: value })}
                  />
                </Grid>
                <Grid item xs={12} md={1.7}>
                  <OfferingInput
                    label="Tithe"
                    value={newEntry.dizimo}
                    onChange={(value) => setNewEntry({ ...newEntry, dizimo: value })}
                  />
                </Grid>
                <Grid item xs={12} md={1.7}>
                  <OfferingInput
                    label="Mission Offering"
                    value={newEntry.ofertaMissao}
                    onChange={(value) =>
                      setNewEntry({ ...newEntry, ofertaMissao: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={1.7}>
                  <OfferingInput
                    label="EBD Offering"
                    value={newEntry.ofertaEBD}
                    onChange={(value) => setNewEntry({ ...newEntry, ofertaEBD: value })}
                  />
                </Grid>
                <Grid item xs={12} md={1.7}>
                  <OfferingInput
                    label="Christian Family Offering"
                    value={newEntry.ofertaFC}
                    onChange={(value) => setNewEntry({ ...newEntry, ofertaFC: value })}
                  />
                </Grid>
                <Grid item xs={12} md={1.7}>
                  <OfferingInput
                    label="Firstfruits Offering"
                    value={newEntry.ofertaPrimicias}
                    onChange={(value) =>
                      setNewEntry({ ...newEntry, ofertaPrimicias: value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={1.7}>
                  <OfferingInput
                    label="Other Offerings"
                    value={newEntry.ofertaOutras}
                    onChange={(value) =>
                      setNewEntry({ ...newEntry, ofertaOutras: value })
                    }
                  />
                </Grid>

              </Grid>
            </Grid>

            {/* Row 3: Subtotal */}
            <Grid item xs={12}>
              <Typography sx={{ mt: 2, fontWeight: "bold", textAlign: "right" }}>
                {t("Subtotal")}: {calculateSubtotal()}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>{t("Cancel")}</Button>
          <Button variant="contained" color="primary" onClick={handleAddEntry}>
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
    </Container>
  );
}
