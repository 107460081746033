import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";
import queryClient from "data/queryClient";

function fetchPublicPeople(page = 0, filter = "") {
  return fetch(
    `/v1/pessoa?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  );
}

const getQueryKey = (page = 0, name = "") => ["public-people", page, name];

export function usePublicPeopleQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchPublicPeople(page, filter),
  });
}

export function ensurePublicPeopleQuery({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchPublicPeople(page, filter),
  });
}

export function invalidatePublicPeopleQuery() {
  return queryClient.invalidateQueries(["public-people"]);
}
