import { ensurePersonQuery, usePersonQuery } from "data/queries/queryPerson";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";

export async function loader({ params: { id } }) {
  return await ensurePersonQuery(id);
}

export default function MembersViewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: queryData } = usePersonQuery({ id });
  const member = queryData?.data;

  return (
    <Container>
      <DetailsPageHeader
        heading={t("Members")}
        label={member?.name ?? "..."}
        routeIndex="/members"
        routeUpdate={`/members/${id}/update`}
      />

      {member && (
        <Stack spacing={4}>
          <FormCard title="Dados de Contato">
            <dl>
              <dt>{t("Congregation")}</dt>
              <dd>{member.congregacao.name}</dd>

              <dt>{t("Name")}</dt>
              <dd>{member.name}</dd>

              <dt>{t("Email")}</dt>
              <dd>{member.email}</dd>

              <dt>{t("Phone")}</dt>
              <dd>{member.phone}</dd>

              <dt>{t("Preferred contact method:")}</dt>
              <dd>
                {member.prefContact === "EMAIL" ? t("Email") : t("Phone")}
              </dd>
            </dl>
          </FormCard>
          <FormCard title="Dados pessoais">
            <dl>
              <dt>{t("Date of birth")}</dt>
              <dd>{member.birthDate}</dd>

              <dt>{t("Address")}</dt>
              <dd>
                {member.address && (
                  <>
                    {member.address.street}
                    <br />
                    {member.address.city}, {member.address.country},{" "}
                    {member.address.postCode}
                  </>
                )}
              </dd>

              <dt>{t("Marital Status")}</dt>
              <dd>{mapMaritalStatus(member.maritalStatus, t)}</dd>

              <dt>{t("Marriage Date")}</dt>
              <dd>{member.marriageAt}</dd>
            </dl>
          </FormCard>
          <FormCard title="Dados familiares">
            {member.familyMembers?.map((m) => (
              <dl key={m.id}>
                <dt>{t("Name")}</dt>
                <dd>{m.name}</dd>
                <dt>{t("Date of birth")}</dt>
                <dd>{m.birthDate}</dd>
                <dt>{t("Relation")}</dt>
                <dd>{mapFamilyMemberType(m.type, t)}</dd>
                <dt>{t("Also a member?")}</dt>
                <dd>{m.isMember ? t("Yes") : t("No")}</dd>
              </dl>
            ))}
          </FormCard>
          <FormCard title="Dados congregacionais">
            <dl>
              <dt>{t("Role")}</dt>
              <dd>
                {member.tipo != null && member.tipo !== ""
                  ? t(member.tipo)
                  : "-"}
              </dd>

              <dt>{t("Baptism Date")}</dt>
              <dd>{member.waterBaptismAt}</dd>

              <dt>{t("Holy Spirit Baptism Date")}</dt>
              <dd>{member.spiritBaptismAt}</dd>

              <dt>{t("Origin Church")}</dt>
              <dd>{member.originatingChurch}</dd>

              <dt>{t("Department")}</dt>
              <dd>{member.departments.map(d => t(d)).join(', ')}</dd>

              <dt>{t("Membership Method")}</dt>
              <dd>{mapCreationStatus(member.creationStatus, t)}</dd>

              <dt>{t("Permissions")}</dt>
              <dd>{member.roles.map(d => t(d)).join(', ')}</dd>
            </dl>
          </FormCard>
          <FormCard title="Documentos Adicionais">
            <dl>
              {member.documents.map((d) => (
                <Fragment key={d.id}>
                  <dt>{mapDocumentType(d.type, t)}</dt>
                  <dd>{d.name ?? d.id}</dd>
                </Fragment>
              ))}
            </dl>
          </FormCard>
        </Stack>
      )}
    </Container>
  );
}

function FormCard({ title, children }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent as={Stack} spacing={3}>
        {children}
      </CardContent>
    </Card>
  );
}

const mapMaritalStatus = (value, t) => {
  switch (value) {
    case "SINGLE":
      return t("Single");
    case "MARRIED":
      return t("Married");
    case "DIVORCED":
      return t("Divorced");
    case "WIDOWED":
      return t("Widowed");
    default:
      return "";
  }
};

const mapDocumentType = (value, t) => {
  switch (value) {
    case "OFFICIAL_PHOTO":
      return t("Foto");
    case "OFFICIAL_DOCUMENT":
      return t("Document Pessoal");
    case "MARRIAGE_DOCUMENT":
      return t("Certidão de Casamento");
    case "THEOLOGY_CERTIFICATE":
      return t("Certificado de Curso (Teologia)");
    case "COURSE_CERTIFICATE":
      return t("Certificado de Curso (outros)");
    case "TRANSFERRED_LETTER":
      return t("Carta de Transferência");
    case "OTHER":
    default:
      return t("Outro");
  }
};

const mapFamilyMemberType = (value, t) => {
  switch (value) {
    case "SPOUSE":
      return t("Spouse");
    case "CHILD":
      return t("Child");
    default:
      return "-";
  }
};

const mapCreationStatus = (value, t) => {
  switch (value) {
    case "RECEIVED_BY_BAPTISM":
      return t("Batismo");
    case "RECEIVED_WITHOUT_LETTER":
      return t("Recebimento - Sem Carta");
    case "TRANSFERRED_WITH_LETTER_SAME_MINISTRY":
      return t("Transferência -  Com Carta de Mudança - Mesmo Ministério");
    case "TRANSFERRED_WITH_LETTER":
      return t("Transferência -  Com Carta de Mudança - Ministério Diferente");
    default:
      return "-";
  }
};
