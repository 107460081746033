// @mui
import MUIGlobalStyles from "@mui/material/GlobalStyles";

export default function ThemeGlobalStyles() {
  return (
    <MUIGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
        },
        html: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          scrollMarginTop: "95px",
          '&[aria-hidden="true"]': {
            scrollMarginTop: "115px",
          },
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        img: {
          display: "block",
          maxWidth: "100%",
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        dl: {
          margin: 0,
          display: "grid",
          gridTemplateColumns: "max-content auto",
        },
        dt: {
          gridColumnStart: 1,
        },
        dd: {
          gridColumnStart: 2,
        },
      }}
    />
  );
}
