import fetch from "data/fetch";
import { useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { mutateRegionalConfig } from "data/mutations/mutateRegionalConfig";
import ResourceConfigForm from "shared/components/ResourceConfig/ResourceConfigForm.react";

export async function loader({ params: { id } }) {
  const { data: config } = await fetch(`/v1/regional/${id}/config`);
  return {
    regionalId: id,
    config: config,
  };
}

export default function RegionalConfigEditPage() {
  const data = useLoaderData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      const success = await mutateRegionalConfig({ data: formData, regionalId: data.regionalId });
      if (!success) {
        throw new Error("Error submitting the form");
      }
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }

    navigate(`/regional/${data.regionalId}`);
  };

  return (
      <ResourceConfigForm 
        data={data.config} 
        onSubmit={onSubmit} 
        error={error}
        breadcrumbs={
          <Breadcrumbs
            heading={t("Edit Regional Config")}
            links={[
              {
                name: t("Regionals"),
                to: "/regional/list",
              },
              {
                name: data.regionalId,
                to: `/regional/${data.regionalId}`,
              },
              { name: t("Edit Config") },
            ]}
            sx={{ mb: { xs: 3, md: 5 } }}
          />
        }/>
  );
}
