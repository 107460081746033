import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const countries = [
  { code: "HT", label: "Haiti", flag: "🇭🇹" },
  { code: "DK", label: "Denmark", flag: "🇩🇰" },
  { code: "PT", label: "Portugal", flag: "🇵🇹" },
  { code: "ES", label: "Spain", flag: "🇪🇸" },
  { code: "US", label: "United States", flag: "🇺🇸" },
  { code: "FR", label: "France", flag: "🇫🇷" },
  { code: "IT", label: "Italy", flag: "🇮🇹" },
  { code: "NZ", label: "New Zealand", flag: "🇳🇿" },
  { code: "GB", label: "United Kingdom", flag: "🇬🇧" },
  { code: "AU", label: "Australia", flag: "🇦🇺" },
  { code: "BE", label: "Belgium", flag: "🇧🇪" },
  { code: "DE", label: "Germany", flag: "🇩🇪" },
  { code: "CH", label: "Switzerland", flag: "🇨🇭" },
  { code: "NL", label: "Netherlands", flag: "🇳🇱" },
];

export function CountrySelector({ value, onChange, error, helperText }) {
  const { t } = useTranslation();
  return (
    <Autocomplete
      options={countries}
      getOptionLabel={(option) => `${option.flag} ${t(option.label)}`}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={t("Country")}
        />
      )}
      value={countries.find((c) => c.code === value) || null}
      onChange={(event, newValue) => onChange(newValue?.code || "")}
      isOptionEqualToValue={(option, value) => option.code === value.code}
    />
  );
}
