import { useTranslation } from "react-i18next";
import FormCard from "shared/components/FormCard/FormCard.react";
import RoleSelect from "shared/components/RoleSelect/RoleSelect.react";

function MemberFormPermissionsCard({ roles, onRolesChange }) {
  const { t } = useTranslation();

  return (
    <FormCard title={t("Permissions")}>
      <RoleSelect value={roles} onChange={onRolesChange} />
    </FormCard>
  );
}

export default MemberFormPermissionsCard;
