import { createBrowserRouter } from "react-router-dom";
import FamiliaCristaNewPage, {
  action as familiaCristaNewAction,
  loader as familiaCristaNewLoader,
} from "pages/familia-crista/FamiliaCristaNewPage.react";
import FamiliaCristaIndexPage, {
  loader as familiaCristaIndexLoader,
} from "pages/familia-crista/FamiliaCristaIndexPage.react";
import FamiliaCristaFilePage from "pages/familia-crista/FamiliaCristaFilePage.react";
import FamiliaCristaFileNewPage from "pages/familia-crista/FamiliaCristaFileNewPage.react";
import FamiliaCristaEditPage, {
  action as familiaCristaEditAction,
  loader as familiaCristaEditLoader,
} from "pages/familia-crista/FamiliaCristaEditPage.react";
import CaringGroupsNewPage, {
  action as caringGroupsNewAction,
  loader as caringGroupsNewLoader,
} from "pages/caring_groups/CaringGroupsNewPage.react";
import CaringGroupsReportsNewPage, {
  action as caringGroupsReportsNewAction,
  loader as caringGroupsReportsNewLoader,
} from "pages/caring_groups_reports/CaringGroupsReportsNewPage.react";
import FamiliaCristaReportsNewPage, {
  action as familiaCristaReportsNewAction,
  loader as familiaCristaReportsNewLoader,
} from "pages/familia-crista/FamiliaCristaReportsNewPage.react";
import CaringGroupsViewPage, {
  loader as caringGroupsViewLoader,
} from "pages/caring_groups/CaringGroupsViewPage.react";
import FamiliaCristaViewPage, {
  loader as familiaCristaViewLoader,
} from "pages/familia-crista/FamiliaCristaViewPage.react";
import FamiliaCristaReportsIndexPage, {
  loader as familiaCristaReportsIndexLoader,
} from "pages/familia-crista/FamiliaCristaReportsIndexPage.react";
import FamiliaCristaAnalyticsPage from "pages/familia-crista/FamiliaCristaAnalyticsPage.react";

import CultoSecretariaIndexPage, {
  loader as cultoSecretariaIndexLoader,
} from "pages/culto-secretaria/CultoSecretariaIndexPage.react";
import CultoSecretariaNewPage, {
  action as cultoSecretariaNewAction,
  loader as cultoSecretariaNewLoader,
} from "pages/culto-secretaria/CultoSecretariaNewPage.react";
import CultoSecretariaEditPage, {
  loader as cultoSecretariaEditLoader,
} from "pages/culto-secretaria/CultoSecretariaEditPage.react";
import CultoSecretariaAnalyticsPage from "pages/culto-secretaria/CultoSecretariaAnalyticsPage.react";

import TreasuryIndexPage, {
  loader as treasuryIndexLoader,
} from "pages/treasury/TreasuryIndexPage.react";
import TreasuryNewPage from "pages/treasury/TreasuryNewPage.react";
import TreasuryAnalyticsPage from "pages/treasury/TreasuryAnalyticsPage.react";
import TreasuryDonationPage from "pages/treasury/TreasuryDonationPage.react";
import TreasuryDonationNewPage from "pages/treasury/TreasuryDonationNewPage.react";

import Friends4EverIndexPage, {
  loader as friends4EverIndexLoader,
} from "pages/friends-4ever/Friends4EverIndexPage.react";
import Friends4EverNewPage, {
  action as friends4EverNewAction,
  loader as friends4EverNewLoader,
} from "pages/friends-4ever/Friends4EverNewPage.react";
import Friends4EverAnalyticsPage from "pages/friends-4ever/Friends4EverAnalyticsPage.react";

import CaringGroupsIndexPage, {
  loader as caringGroupsIndexLoader,
} from "pages/caring_groups/CaringGroupsIndexPage.react";
import CaringGroupsAnalyticsPage from "pages/caring_groups/CaringGroupsAnalyticsPage.react";
import CaringGroupsEditPage, {
  action as caringGroupsEditAction,
  loader as caringGroupsEditLoader,
} from "pages/caring_groups/CaringGroupsEditPage.react";
import CaringGroupsReportsIndexPage, {
  loader as caringGroupsReportsIndexLoader,
} from "pages/caring_groups_reports/CaringGroupsReportsIndexPage.react";
import CaringGroupsReportsViewPage, {
  loader as caringGroupsReportsViewLoader,
} from "pages/caring_groups_reports/CaringGroupsReportsViewPage.react";
import GuestIndexPage, {
  loader as guestIndexLoader,
} from "pages/guest/GuestIndexPage.react";
import GuestNewPage, {
  loader as guestNewLoader,
} from "pages/guest/GuestNewPage.react";
import GuestNewPublicPage, {
  loader as guestPublicLoader,
} from "pages/guest/GuestNewPublicPage.react";
import LoginPage, { action as loginAction } from "pages/login/LoginPage.react";
import MembersIndexPage, {
  loader as membersIndexLoader,
} from "pages/members/MembersIndexPage.react";
import MembersNewPage from "pages/members/MembersNewPage.react";
import MembersAnalyticsPage from "pages/members/MembersAnalyticsPage.react";
import MembersViewPage, {
  loader as memberViewLoader,
} from "pages/members/MembersViewPage.react";
import MembersUpdatePage, {
  loader as memberUpdateLoader,
} from "pages/members/MembersUpdatePage.react";
import RootPage, { loader as rootLoader } from "pages/RootPage.react";
import RootError from "pages/RootError.react";
import RootGlobalError from "pages/RootGlobalError.react";
import ProfileEditPage from "pages/members/ProfileEditPage.react";
import AdminIndexPage from "pages/admin/AdminIndexPage.react";
import PermissionsIndexPage from "pages/system-settings/PermissionsIndexPage.react";
import * as Auth from "shared/auth/Auth";
import { wrapCreateBrowserRouter } from "@sentry/react";

import CongregationEditPage, {
  loader as congregationEditLoader,
} from "pages/congregation/CongregationEditPage.react";
import CongregationNewPage, {
  loader as congregationNewLoader,
} from "pages/congregation/CongregationNewPage.react";

import CongregationListPage, {
  loader as congregationListLoader,
} from "pages/congregation/CongregationListPage.react";

import RegionalEditPage, {
  loader as regionalEditLoader,
} from "pages/regional/RegionalEditPage.react";

import RegionalConfigEditPage, {
  loader as regionalConfigEditLoader,
} from "pages/regional/RegionalConfigEditPage.react";

import RegionalNewPage, {
  loader as regionalNewLoader,
} from "pages/regional/RegionalNewPage.react";

import RegionalListPage, {
  loader as regionalListLoader,
} from "pages/regional/RegionalListPage.react";
import PreDonationNewPage, {
  loader as preDonationNewLoader,
} from "pages/pre-donation/PreDonationNewPage.react";
import PreDonationConfirmationPage, {
  loader as preDonationConfirmationLoader,
} from "pages/pre-donation/PreDonationConfirmationPage.react";
import HomePage from "pages/home/HomePage.react";

/**
 * Create the browser router instance. All routing should be done above through
 * public v.s. private route constants.
 */
export default function setupRouter() {
  const protectedRoutes = (route) => {
    route.loader = Auth.protectedLoaderFactory(route.loader);

    if (route.children) {
      route.children.forEach(protectedRoutes);
    }

    return route;
  };

  return wrapCreateBrowserRouter(createBrowserRouter)([
    // public
    {
      path: "/convite",
      loader: guestPublicLoader,
      element: <GuestNewPublicPage />,
      errorElement: <RootGlobalError />,
    },
    {
      path: "/login",
      loader: Auth.loginRouteLoader,
      action: loginAction,
      element: <LoginPage />,
      errorElement: <RootGlobalError />,
    },
    {
      path: "/login-callback",
      loader: Auth.loginCallbackRouteLoader,
      errorElement: <RootGlobalError />,
    },
    {
      path: "/message",
      children: [
        {
          path: "pre-donation-confirmation",
          loader: preDonationConfirmationLoader,
          element: <PreDonationConfirmationPage />,
        }
      ]

    },
    // private
    protectedRoutes({
      path: "/",
      loader: rootLoader,
      element: <RootPage />,
      errorElement: <RootError />,
      children: [
        { index: true, element: <HomePage /> },
        {
          path: "auth0",
          children: [
            {
              index: true,
              element: <AdminIndexPage />,
            },
          ],
        },
        {
          path: "profile",
          element: <ProfileEditPage />,
        },
        {
          path: "guests",
          children: [
            {
              index: true,
              loader: guestNewLoader,
              element: <GuestNewPage />,
            },
            {
              path: "new",
              loader: guestNewLoader,
              element: <GuestNewPage />,
            },
            {
              path: "list",
              loader: guestIndexLoader,
              element: <GuestIndexPage />,
            },
          ],
        },
        {
          path: "caring_groups",
          children: [
            {
              index: true,
              loader: caringGroupsIndexLoader,
              element: <CaringGroupsIndexPage />,
            },
            {
              path: "new",
              loader: caringGroupsNewLoader,
              action: caringGroupsNewAction,
              element: <CaringGroupsNewPage />,
            },
            {
              path: ":id",
              loader: caringGroupsViewLoader,
              element: <CaringGroupsViewPage />,
            },
            {
              path: ":id/update",
              loader: caringGroupsEditLoader,
              action: caringGroupsEditAction,
              element: <CaringGroupsEditPage />,
            },
            {
              path: ":id/reports",
              children: [
                {
                  path: "new",
                  loader: caringGroupsReportsNewLoader,
                  action: caringGroupsReportsNewAction,
                  element: <CaringGroupsReportsNewPage />,
                },
              ],
            },
            {
              path: "analytics",
              element: <CaringGroupsAnalyticsPage />,
            },
          ],
        },
        {
          path: "culto-secretaria",
          children: [
            {
              index: true,
              loader: cultoSecretariaIndexLoader,
              element: <CultoSecretariaIndexPage />,
            },
            {
              path: ":id",
              loader: cultoSecretariaEditLoader,
              element: <CultoSecretariaEditPage />,
            },
            {
              path: "new",
              loader: cultoSecretariaNewLoader,
              action: cultoSecretariaNewAction,
              element: <CultoSecretariaNewPage />,
            },
            {
              path: "analytics",
              element: <CultoSecretariaAnalyticsPage />,
            },
          ],
        },
        {
          path: "treasury",
          children: [
            {
              index: true,
              loader: cultoSecretariaIndexLoader,
              element: <CultoSecretariaIndexPage />,
            },
            {
              path: "new",
              element: <TreasuryNewPage />,
            },
            {
              path: "analytics",
              element: <TreasuryAnalyticsPage />,
            },
            {
              path: "donation",
              element: <TreasuryDonationPage />,
              children: [
                {
                  path: "new",
                  element: <TreasuryDonationNewPage layout="dialog" />,
                },
              ],
            },
            {
              path: ":id",
              loader: treasuryIndexLoader,
              element: <TreasuryIndexPage />,
            },
            {
              path: ":id/new",
              element: <TreasuryNewPage />,
            },
          ],
        },        
        {
          path: "friends-4ever",
          children: [
            {
              index: true,
              loader: friends4EverIndexLoader,
              element: <Friends4EverIndexPage />,
            },
            {
              path: "new",
              loader: friends4EverNewLoader,
              action: friends4EverNewAction,
              element: <Friends4EverNewPage />,
            },
            {
              path: "analytics",
              element: <Friends4EverAnalyticsPage />,
            },
          ],
        },
        {
          id: "id-caring_groups_reports",
          children: [
            {
              path: "caring_groups_reports",
              loader: caringGroupsReportsIndexLoader,
              element: <CaringGroupsReportsIndexPage />,
              children: [
                {
                  path: "new",
                  loader: caringGroupsReportsNewLoader,
                  element: <CaringGroupsReportsNewPage layout="dialog" />,
                },
                {
                  path: ":id",
                  loader: caringGroupsReportsViewLoader,
                  element: <CaringGroupsReportsViewPage />,
                },
              ],
            },
          ],
        },
        {
          path: "familia-crista",
          children: [
            {
              index: true,
              path: "list",
              loader: familiaCristaIndexLoader,
              element: <FamiliaCristaIndexPage />,
            },
            {
              path: "analytics",
              element: <FamiliaCristaAnalyticsPage />,
            },
            {
              path: "reports",
              loader: familiaCristaReportsIndexLoader,
              element: <FamiliaCristaReportsIndexPage />,
            },
            {
              path: "files",
              element: <FamiliaCristaFilePage />,
            },
            {
              path: "upload-file",
              element: <FamiliaCristaFileNewPage />,
            },
            {
              path: "new",
              loader: familiaCristaNewLoader,
              action: familiaCristaNewAction,
              element: <FamiliaCristaNewPage />,
            },
            {
              path: ":id",
              loader: familiaCristaViewLoader,
              element: <FamiliaCristaViewPage />,
            },
            {
              path: ":id/update",
              loader: familiaCristaEditLoader,
              action: familiaCristaEditAction,
              element: <FamiliaCristaEditPage />,
            },
            {
              path: ":id/reports",
              children: [
                {
                  path: "new",
                  loader: familiaCristaReportsNewLoader,
                  action: familiaCristaReportsNewAction,
                  element: <FamiliaCristaReportsNewPage />,
                },
              ],
            },
          ],
        },
        {
          path: "members",
          children: [
            {
              index: true,
              loader: membersIndexLoader,
              element: <MembersIndexPage />,
            },
            {
              path: "new",
              element: <MembersNewPage />,
            },
            {
              path: "analytics",
              element: <MembersAnalyticsPage />,
            },
            {
              path: ":id",
              loader: memberViewLoader,
              element: <MembersViewPage />,
            },
            {
              path: ":id/update",
              loader: memberUpdateLoader,
              element: <MembersUpdatePage />,
            },
          ],
        },
        {
          path: "congregation",
          children: [
            {
              path: "list",
              loader: congregationListLoader,
              element: <CongregationListPage />,
            },
            {
              path: "new",
              loader: congregationNewLoader,
              element: <CongregationNewPage />,
            },
            {
              path: ":id",
              loader: congregationEditLoader,
              element: <CongregationEditPage />,
            },
          ],
        },
        {
          path: "regional",
          children: [
            {
              path: "list",
              loader: regionalListLoader,
              element: <RegionalListPage />,
            },
            {
              path: ":id",
              loader: regionalEditLoader,
              element: <RegionalEditPage />,
            },
            {
              path: ":id/config",
              loader: regionalConfigEditLoader,
              element: <RegionalConfigEditPage />,
            },
            {
              path: "new",
              loader: regionalNewLoader,
              element: <RegionalNewPage />,
            },
          ],
        },
        {
          path: "pre-donation",
          children: [
            {
              index: true,
              loader: preDonationNewLoader,
              element: <PreDonationNewPage />,
            },
          ],
        },
        {
          path: "system-settings",
          children: [
            {
              index: true,
              element: <PermissionsIndexPage />,
            },
            {
              path: "permissions",
              element: <PermissionsIndexPage />,
            },
          ],
        },
      ],
    }),
  ]);
}
