import fetch from "data/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import TreasuryForm from "./components/TreasuryForm.react";
import { invalidateTreasuryQuery } from "data/queries/treasury/queryTreasury";

export default function TreasuryNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // Get `relatorioCultoSecretariaId` from route params
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    setError(null);

    try {
      await fetch(`/v1/relatorio-culto-tesouraria`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      invalidateTreasuryQuery();
      navigate(`/treasury/${id}`);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <TreasuryForm
      onSubmit={onSubmit}
      error={error}
      relatorioCultoSecretariaId={id} // Pass relatorioCultoSecretariaId to the form
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new treasury report")}
          links={[
            { name: t("Treasury"), to: "/treasury" },
            { name: t("Treasury List"), to: `/treasury/${id}` },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
