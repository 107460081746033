import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

// Fetch Donations with pagination and filters
function fetchDonations(page = 0, filter = "") {
  return fetch(
    `/v1/doacoes?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  );
}

// Generate Query Key for Donations
const getQueryKey = (page = 0, filter = "") => ["donations", page, filter];

// Custom Hook for Donations Query with Filter Support
export function useDonationsQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchDonations(page, filter),
  });
}

// Ensure Query Data for Donations
export function ensureDonationsQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchDonations(page, filter),
  });
}

// Invalidate Donations Query
export function invalidateDonationsQuery() {
  return queryClient.invalidateQueries(["donations"]);
}
