import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import PreDonationForm from "./PreDonationForm.react";
import { mutatePreDonation } from "data/mutations/mutatePreDonation";

export async function loader() {
  // No data to load
  return {};
}

export default function PreDonationNewPage() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      const res = await mutatePreDonation(formData);
      if (!res.success) {
        throw new Error("Error submitting the form");
      }

      window.location.href = res.data.paymentLink;

    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }
  };

  return (
    <PreDonationForm
      data={{}}
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("New Donation")}
          links={[]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
