import fetch from "data/fetch";

export async function mutateRegionalConfig({
  regionalId,
  data,
}) {
  const url = `/v1/regional/${regionalId}/config`;
  const method = "PUT";

  // Convert the data object into the desired format
  const formattedData = {
    data: Object.entries(data).map(([key, value]) => ({ key, value })),
  };

  const body = JSON.stringify(formattedData);

  try {
    await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}
