import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import blankToNull from "shared/utils/blankToNull";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Container from "@mui/material/Container";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import formFocusOnError from "shared/utils/formFocusOnError";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import MemberFormCongregationalInfoCard from "./MemberFormCongregationalInfoCard";
import MemberFormContactInfoCard, {
  PrefContact,
} from "./MemberFormContactInfoCard";
import MemberFormDocumentsInfoCard from "./MemberFormDocumentsInfoCard";
import MemberFormFamilyInfoCard from "./MemberFormFamilyInfoCard";
import MemberFormPermissionsCard from "./MemberFormPermissionsCard";
import MemberFormPersonalInfoCard from "./MemberFormPersonalInfoCard";
import MemberFormStatusInfoCard, {
  MemberStatus,
} from "./MemberFormStatusInfoCard";
import moment from "moment";
import Stack from "@mui/material/Stack";
import { formatPhone } from "utils/PhoneValidator";

const INITIAL_STATE = {
  addressCity: "",
  addressCountry: "",
  addressPostCode: "",
  addressState: "",
  addressStreet: "",
  birthDate: null,
  childrenInfo: null,
  churchDepartment: [],
  congregation: null,
  creationStatus: "",
  documents: null,
  email: "",
  gender: "",
  maritalStatus: "",
  marriageAt: null,
  memberStatus: MemberStatus.ACTIVE,
  memberType: "",
  name: "",
  originatingChurch: "",
  phone: "",
  photo: null,
  photoFileId: null,
  prefContact: PrefContact.EMAIL,
  roles: [],
  spiritBaptismAt: null,
  spouseBirthDate: null,
  spouseIsMember: false,
  spouseName: "",
  waterBaptismAt: null,
};

export default function MemberForm({
  breadcrumbs,
  member,
  onSubmit: onSubmitProp,
  onDelete: onDeleteProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [formState, setFormState, dataInitializedRef] =
    useInitialFormState(member);
  const [formErrors, setFormErrors, onFieldChange] = useFormChangeHandler(
    formState,
    setFormState
  );
  const [onAddChild, onRemoveChild] = useChildEventHandlers(
    formState,
    setFormState
  );
  const [onAddDocument, onRemoveDocument] = useDocumentEventHandlers(
    formState,
    setFormState
  );
  const statusInfoRef = useRef();
  const contactInfoRef = useRef();
  const personalInfoRef = useRef();
  const familyInfoRef = useRef();
  const congregationalInfoRef = useRef();
  const documentsCardRef = useRef();
  const [onSubmit, isSubmitting, error] = useFormSubmit(
    formState,
    statusInfoRef,
    contactInfoRef,
    personalInfoRef,
    familyInfoRef,
    congregationalInfoRef,
    documentsCardRef,
    dataInitializedRef,
    setFormErrors,
    onSubmitProp
  );

  // re-mount the form fields when initial state is ready
  const initialLoadKey = dataInitializedRef.current ? "kReady" : "kLoading";

  return (
    <>
      <Container>{breadcrumbs}</Container>
      <Container
        component="form"
        key={initialLoadKey}
        maxWidth="sm"
        method="post"
        noValidate={true}
        onSubmit={onSubmit}
      >
        <Stack spacing={4}>
          <MemberFormStatusInfoCard
            formErrors={formErrors}
            memberType={formState.memberType}
            onMemberTypeChange={onFieldChange("memberType")}
            onStatusChange={onFieldChange("memberStatus")}
            ref={statusInfoRef}
            memberStatus={formState.memberStatus}
          />
          <MemberFormContactInfoCard
            congregation={formState.congregation}
            congregationDisabled={false}
            email={formState.email}
            emailDisabled={false}
            formErrors={formErrors}
            gender={formState.gender}
            memberType={formState.memberType}
            name={formState.name}
            onCongregationChange={onFieldChange("congregation")}
            onEmailChange={onFieldChange("email")}
            onGenderChange={onFieldChange("gender")}
            onNameChange={onFieldChange("name")}
            onPhoneChange={onFieldChange("phone")}
            onPrefContactChange={onFieldChange("prefContact")}
            phone={formState.phone}
            prefContact={formState.prefContact}
            ref={contactInfoRef}
          />
          <MemberFormPersonalInfoCard
            addressCity={formState.addressCity}
            addressCountry={formState.addressCountry}
            addressPostCode={formState.addressPostCode}
            addressState={formState.addressState}
            addressStreet={formState.addressStreet}
            birthDate={formState.birthDate}
            formErrors={formErrors}
            memberType={formState.memberType}
            onAddressCityChange={onFieldChange("addressCity")}
            onAddressCountryChange={onFieldChange("addressCountry")}
            onAddressPostCodeChange={onFieldChange("addressPostCode")}
            onAddressStateChange={onFieldChange("addressState")}
            onAddressStreetChange={onFieldChange("addressStreet")}
            onBirthDateChange={onFieldChange("birthDate")}
            onPhotoChange={onFieldChange("photo")}
            photo={formState.photo}
            ref={personalInfoRef}
          />
          <MemberFormFamilyInfoCard
            ref={familyInfoRef}
            childrenInfo={formState.childrenInfo}
            formErrors={formErrors}
            maritalStatus={formState.maritalStatus}
            marriageAt={formState.marriageAt}
            memberType={formState.memberType}
            onAddChildClick={onAddChild}
            onChildChange={onFieldChange("child")}
            onMaritalStatusChange={onFieldChange("maritalStatus")}
            onMarriageAtChange={onFieldChange("marriageAt")}
            onRemoveChildClick={onRemoveChild}
            onSpouseBirthDateChange={onFieldChange("spouseBirthDate")}
            onSpouseIsMemberChange={onFieldChange("spouseIsMember")}
            onSpouseNameChange={onFieldChange("spouseName")}
            spouseBirthDate={formState.spouseBirthDate}
            spouseIsMember={formState.spouseIsMember}
            spouseName={formState.spouseName}
          />
          <MemberFormCongregationalInfoCard
            churchDepartment={formState.churchDepartment}
            creationStatus={formState.creationStatus}
            extraFieldsVisible={true}
            formErrors={formErrors}
            memberType={formState.memberType}
            onChurchDepartmentChange={onFieldChange("churchDepartment")}
            onCreationStatusChange={onFieldChange("creationStatus")}
            onOriginatingChurchChange={onFieldChange("originatingChurch")}
            onSpiritBaptismAtChange={onFieldChange("spiritBaptismAt")}
            onWaterBaptismAtChange={onFieldChange("waterBaptismAt")}
            originatingChurch={formState.originatingChurch}
            ref={congregationalInfoRef}
            spiritBaptismAt={formState.spiritBaptismAt}
            waterBaptismAt={formState.waterBaptismAt}
          />
          <MemberFormPermissionsCard
            roles={formState.roles}
            onRolesChange={onFieldChange("roles")}
          />
          <MemberFormDocumentsInfoCard
            ref={documentsCardRef}
            documents={formState.documents}
            onDocumentChange={onFieldChange("document")}
            formErrors={formErrors}
            onAddDocumentClick={onAddDocument}
            onRemoveDocumentClick={onRemoveDocument}
          />

          <ErrorAlert error={errorProp} />
          <ErrorAlert error={error} />

          <Stack direction="row" justifyContent="flex-end">
            <Button
              color="success"
              disabled={isSubmitting}
              size="large"
              startIcon={member ? <CheckIcon /> : <AddIcon />}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

/**
 * Load the profile data into the initial form statae.
 */
function useInitialFormState(data) {
  const dataInitializedRef = useRef(false);
  const [formState, setFormState] = useState(INITIAL_STATE);

  // new member
  if (data === false) {
    dataInitializedRef.current = true;
  }
  // update member and loaded
  else if (data != null && !dataInitializedRef.current) {
    dataInitializedRef.current = true;

    const children =
      data.familyMembers?.filter((m) => m.type === "CHILD") || [];
    const spouse = data.familyMembers?.find((m) => m.type === "SPOUSE");

    const photo = data.documents?.find((d) => d.type === "OFFICIAL_PHOTO");
    const documents = data.documents?.filter(
      (d) => d.type !== "OFFICIAL_PHOTO"
    );
    const photoFileId = photo?.file?.id;

    const initialState = {
      addressCity: data.address?.city,
      addressCountry: data.address?.country,
      addressPostCode: data.address?.postCode,
      addressState: data.address?.state,
      addressStreet: data.address?.street,
      birthDate: dateConvert(data.birthDate),
      childrenInfo: children.map((child) => ({
        name: child.name,
        birthDate: dateConvert(child.birthDate),
        isMember: child.isMember === true,
      })),
      churchDepartment: data.departments,
      congregation: data.congregacao,
      creationStatus: data.creationStatus,
      documents,
      email: data.email,
      gender: data.gender,
      maritalStatus: data.maritalStatus,
      marriageAt: dateConvert(data.marriageAt),
      memberStatus: data.status,
      memberType: data.tipo,
      name: data.name,
      originatingChurch: data.originatingChurch,
      phone: data.phone,
      photo: photoFileId
        ? `/api/v1/file/${photoFileId}?width=160&height=160`
        : null,
      photoFileId: photoFileId,
      prefContact: data.prefContact,
      roles: data.roles,
      spiritBaptismAt: dateConvert(data.spiritBaptismAt),
      spouseBirthDate: dateConvert(spouse?.birthDate),
      spouseIsMember: spouse?.isMember === true,
      spouseName: spouse?.name,
      waterBaptismAt: dateConvert(data.waterBaptismAt),
    };

    // the form is fully controlled, so let's fill any nulls with a valid
    // initial value
    for (const k in initialState) {
      if (initialState[k] == null) {
        initialState[k] = INITIAL_STATE[k];
      }
    }

    setFormState(initialState);
  }

  return [formState, setFormState, dataInitializedRef];
}

/**
 * Handles changes to form inputs and places into form state
 */
function useFormChangeHandler(formState, setFormState) {
  const [formErrors, setFormErrors] = useState({});

  const onFieldChange = (field) => (value, index) => {
    const newFormState = { ...formState };
    const newFormErrors = { ...formErrors };

    if (field === "child") {
      newFormState.childrenInfo =
        formState.childrenInfo == null ? [] : [...formState.childrenInfo];
      newFormState.childrenInfo[index] = value;

      newFormErrors.childrenInfo =
        formErrors.childrenInfo == null ? {} : { ...formErrors.childrenInfo };
      newFormErrors.childrenInfo[`${index}_name`] = null;
      newFormErrors.childrenInfo[`${index}_birthDate`] = null;
    } else if (field === "document") {
      newFormState.documents =
        formState.documents == null ? [] : [...formState.documents];
      newFormState.documents[index] = value;

      newFormErrors.documents =
        formErrors.documents == null ? {} : { ...formErrors.documents };
      newFormErrors.documents[`${index}_type`] = null;
    } else if (field === "photo") {
      newFormState.hasPhotoChanged = true;
      newFormState.photo = value;
      newFormState.photoFileId = null; // need new file uploaded
      newFormErrors.photo = null;
    } else {
      newFormState[field] = value;
      newFormErrors[field] = null;
    }

    setFormState(newFormState);
    setFormErrors(newFormErrors);
  };

  return [formErrors, setFormErrors, onFieldChange];
}

/**
 * Handles changes to children-specific info
 */
function useChildEventHandlers(formState, setFormState) {
  const onAddChild = () => {
    const currChildrenInfo = formState.childrenInfo ?? [];
    setFormState({
      ...formState,
      childrenInfo: [
        ...currChildrenInfo,
        { name: "", birthDate: null, isMember: false },
      ],
    });
  };

  const onRemoveChild = (index) => {
    const currChildrenInfo = formState.childrenInfo ?? [];
    const newChildrenInfro = [...currChildrenInfo];
    newChildrenInfro.splice(index, 1);
    setFormState({
      ...formState,
      childrenInfo: newChildrenInfro,
    });
  };

  return [onAddChild, onRemoveChild];
}

/**
 * Handles changes to document-specific info
 */
function useDocumentEventHandlers(formState, setFormState) {
  const onAddDocument = () => {
    const currDocuments = formState.documents ?? [];
    const newDocuments = [
      ...currDocuments,
      {
        clientId: `document_${++docIdIncrement}`,
        file: null,
      },
    ];

    setFormState({
      ...formState,
      documents: newDocuments,
    });
  };

  const onRemoveDocument = (index) => {
    const currDocuments = formState.documents ?? [];
    const newDocuments = [...currDocuments];
    newDocuments[index].removed = true;

    setFormState({
      ...formState,
      documents: newDocuments,
    });
  };

  return [onAddDocument, onRemoveDocument];
}
let docIdIncrement = 0;

/**
 * Validates and submit form
 */
function useFormSubmit(
  formState,
  statusInfoRef,
  contactInfoRef,
  personalInfoRef,
  familyInfoRef,
  congregationalInfoRef,
  documentsCardRef,
  dataInitializedRef,
  setFormErrors,
  onSubmitProp
) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const validateForm = () => {
    const newFormErrors = {};

    statusInfoRef.current.validate(newFormErrors);
    contactInfoRef.current.validate(newFormErrors);
    personalInfoRef.current.validate(newFormErrors);
    familyInfoRef.current.validate(newFormErrors);
    congregationalInfoRef.current.validate(newFormErrors);
    documentsCardRef.current.validate(newFormErrors);

    setFormErrors(newFormErrors);

    const isValid = Object.keys(newFormErrors).length === 0;

    if (!isValid) {
      formFocusOnError();
    }

    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!dataInitializedRef.current || !validateForm()) {
      return;
    }

    setIsSubmitting(true);

    // build familyMembers
    const familyMembers = [];

    if (!isStringNullOrEmpty(formState.spouseName)) {
      familyMembers.push({
        name: formState.spouseName,
        birthDate: dateFormat(formState.spouseBirthDate),
        isMember: formState.spouseIsMember,
        type: "SPOUSE",
      });
    }

    if (formState.childrenInfo != null) {
      formState.childrenInfo
        .filter(
          (c) =>
            !isStringNullOrEmpty(c.name) && !isStringNullOrEmpty(c.birthDate)
        )
        .forEach((child) => {
          familyMembers.push({
            name: child.name,
            birthDate: dateFormat(child.birthDate),
            isMember: child.isMember,
            type: "CHILD",
          });
        });
    }

    // build address
    const street = blankToNull(formState.addressStreet);
    const postCode = blankToNull(formState.addressPostCode);
    const city = blankToNull(formState.addressCity);
    const state = blankToNull(formState.addressState);
    const country = blankToNull(formState.addressCountry);
    const address =
      !isStringNullOrEmpty(street) ||
      !isStringNullOrEmpty(postCode) ||
      !isStringNullOrEmpty(city) ||
      !isStringNullOrEmpty(state) ||
      !isStringNullOrEmpty(country)
        ? {
            street,
            postCode,
            city,
            state,
            country,
          }
        : null;

    // submit
    const payload = {
      address,
      birthDate: dateFormat(formState.birthDate),
      congregationId: formState.congregation?.id ?? null,
      creationStatus: blankToNull(formState.creationStatus),
      churchDepartment: formState.churchDepartment,
      documents: formState.documents,
      email: formState.email,
      familyMembers,
      gender: blankToNull(formState.gender),
      hasPhotoChanged: formState.hasPhotoChanged,
      maritalStatus: blankToNull(formState.maritalStatus),
      marriageAt: dateFormat(formState.marriageAt),
      name: formState.name,
      originatingChurch: blankToNull(formState.originatingChurch),
      phone: formatPhone(formState.phone),
      photo: formState.photo,
      photoFileId: formState.photoFileId,
      prefContact: formState.prefContact,
      roles: formState.roles,
      spiritBaptismAt: dateFormat(formState.spiritBaptismAt),
      memberStatus: formState.memberStatus,
      memberType: formState.memberType,
      waterBaptismAt: dateFormat(formState.waterBaptismAt),
    };

    try {
      await onSubmitProp(payload);
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return [onSubmit, isSubmitting, error];
}

function dateConvert(value) {
  return value == null ? null : (moment(value) ?? null);
}

function dateFormat(value) {
  return dateConvert(value)?.format("YYYY-MM-DD") ?? null;
}
