import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchFriends4Ever(page = 0) {
  return fetch(`/v1/friends-4ver?size=100&index=${page}`);
}

const getQueryKey = (index = 0) => ["friends-4ever", index];

export function useFriends4EverQuery({ page = 0, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page),
    queryFn: () => fetchFriends4Ever(page),
  });
}

export function ensureFriends4EverQueryData({ page = 0 } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page),
    queryFn: () => fetchFriends4Ever(page),
  });
}

export function invalidateFriends4EverQuery() {
  return queryClient.invalidateQueries(["friends-4ever"]);
}
