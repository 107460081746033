import { Form } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import fetch from "data/fetch";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import LogoWordmark from "shared/components/Logo/LogoWordmark.react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { isEmailValid } from "utils/EmailValidator";
import { formatPhone, isPhoneValid } from "utils/PhoneValidator";
import TextField from "@mui/material/TextField";
import { useCallback } from "react";
import { MuiTelInput } from "mui-tel-input";

const PreferredContact = { email: 1, phone: 2 };

export default function GuestSignupForm({ isPublic, data, onSuccess }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [resetKey, setResetKey] = useState(1);
  const onReset = useCallback(() => {
    setIsSuccess(false);
    setResetKey((x) => x + 1);
  }, []);

  return (
    <Container fixed sx={styles.container}>
      {isPublic && <LogoWordmark sx={styles.logo} />}
      {isSuccess ? (
        <SuccessMessage onReset={onReset} />
      ) : (
        <FormPanel
          key={resetKey}
          data={data}
          onSuccess={() => {
            setIsSuccess(true);
            onSuccess?.();
          }}
        />
      )}
      {isPublic && <Footer />}
    </Container>
  );
}

function FormPanel({ data, onSuccess }) {
  const { t } = useTranslation();
  const [name, setName] = useState(data?.name ?? "");
  const [email, setEmail] = useState(data?.email ?? "");
  const [phone, setPhone] = useState(data?.phone ?? "");
  const [congregation, setCongregation] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preferredContactMethod, setPreferredContactMethod] = useState(
    PreferredContact.email
  );
  const hasToFinishRegistration = data?.requiredActions?.some(
    (x) => x === "FINISH_REGISTRATION"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const congregationID = congregation?.id ?? "";

    if (congregationID == null || congregationID === "") {
      setError(t("Congregation is required"));
      return;
    }

    if (name === "") {
      setError(t("Name is required"));
      return;
    }

    if (email === "") {
      setError(t("Email is required"));
      return;
    }

    if (!isEmailValid(email)) {
      setError(t("Email is invalid"));
      return;
    }

    if (phone === "") {
      setError(t("Phone is required"));
      return;
    }

    if (!isPhoneValid(phone)) {
      setError(t("Phone is invalid"));
      return;
    }

    setIsLoading(true);

    try {
      await createPessoa(
        name,
        email,
        phone,
        preferredContactMethod,
        congregationID
      );

      onSuccess();
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  const createPessoa = async (
    name,
    email,
    phone,
    preferredContactMethod,
    congregacaoId
  ) => {
    return await fetch(hasToFinishRegistration ? "/v1/profile" : "/v1/pessoa", {
      method: hasToFinishRegistration ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone: formatPhone(phone),
        congregacaoId,
        prefContact:
          preferredContactMethod === PreferredContact.email ? "EMAIL" : "PHONE",
      }),
    });
  };

  return (
    <Stack
      component={Form}
      spacing={2}
      sx={styles.form}
      onSubmit={handleSubmit}
    >
      <h1>
        {hasToFinishRegistration
          ? t("Complete your registration")
          : t("Guest Registration")}
      </h1>

      <CongregationSelect
        required={true}
        onChange={(value) => setCongregation(value)}
      />

      <TextField
        required={true}
        id="name"
        label={t("Name")}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <TextField
        type="email"
        required={true}
        id="email"
        label={t("Email")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <MuiTelInput
        required
        value={phone || "+1"}
        label={t("Phone")}
        onChange={setPhone}
      />

      <FormControl>
        <FormLabel id="contact-label">
          {t("Preferred contact method:")}
        </FormLabel>
        <RadioGroup
          row={true}
          aria-labelledby="contact-label"
          name="contact-group"
          value={preferredContactMethod}
          onChange={(e) =>
            setPreferredContactMethod(parseInt(e.target.value, 10))
          }
        >
          <FormControlLabel
            value={PreferredContact.email}
            control={<Radio />}
            label={t("Email")}
          />
          <FormControlLabel
            value={PreferredContact.phone}
            control={<Radio />}
            label={t("Phone")}
          />
        </RadioGroup>
      </FormControl>

      <Button
        startIcon={<PersonAddIcon />}
        variant="contained"
        color="success"
        size="large"
        disabled={isLoading}
        type="submit"
      >
        {isLoading ? t("Registering...") : t("Register")}
      </Button>
      <ErrorAlert error={error} />
    </Stack>
  );
}
function SuccessMessage({ onReset }) {
  const { t } = useTranslation();

  return (
    <Stack sx={styles.successMessage}>
      <h1>{t("Thank you!")}</h1>
      <Box>{t("Guest added successfully.")}</Box>
      <Button
        startIcon={<PersonAddIcon />}
        variant="contained"
        size="large"
        sx={{ mt: 3 }}
        onClick={onReset}
      >
        {t("Add new")}
      </Button>
    </Stack>
  );
}

function Footer() {
  const { i18n } = useTranslation();
  const onLanguageClick = (e) => i18n.changeLanguage(e.currentTarget.value);

  return (
    <Stack
      direction="row"
      spacing={2}
      divider={<Divider orientation="vertical" flexItem={true} />}
      style={{ marginTop: 20 }}
    >
      <Button size="small" value="en" onClick={onLanguageClick}>
        English
      </Button>
      <Button size="small" value="pt_BR" onClick={onLanguageClick}>
        Português
      </Button>
      <Button size="small" value="es" onClick={onLanguageClick}>
        Español
      </Button>
    </Stack>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
  },
  logo: {
    width: [300, 370],
    mb: 2,
  },
  form: {
    minWidth: ["100%", 400],
  },
  successMessage: {
    minHeight: 400,
    alignItems: "center",
    justifyContent: "center",
  },
};
