import { useCallback, useId } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

export default function SelectField({
  fullWidth,
  helperText,
  label,
  onChange: onChangeProp,
  required,
  sx,
  ...props
}) {
  const labelId = useId();
  const onChange = useCallback(
    (e) => {
      if (onChangeProp != null) {
        onChangeProp(e.target.value);
      }
    },
    [onChangeProp]
  );

  return (
    <FormControl
      sx={sx}
      fullWidth={fullWidth}
      required={required}
      error={props.error}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select {...props} onChange={onChange} labelId={labelId} label={label} />
      {helperText != null && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
