import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

export default function IndexPageDeleteAction({ id, useMutation }) {
  const { t } = useTranslation();
  const deleteMutation = useMutation();
  const onDeleteAction = useCallback(() => {
    if (window.confirm(t("Are you sure you want to delete this entry?"))) {
      deleteMutation.mutate(id);
    }
  }, [t, deleteMutation, id]);

  return id == null ? null : (
    <IconButton aria-label={t("Delete")} onClick={onDeleteAction}>
      <DeleteIcon />
    </IconButton>
  );
}
