import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Card,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";

export default function FamiliaCristaFileForm({
  onSubmit,
  error,
  breadcrumbs,
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [estudoId, setestudoId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    onSubmit({ file, type, description, estudoId }).finally(() =>
      setIsSubmitting(false)
    );
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      {breadcrumbs}
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          <Typography variant="h6">{t("Upload File")}</Typography>

          <input
            accept="*/*"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadFileIcon />}
            >
              {t("Choose File")}
            </Button>
          </label>
          {file && <Typography>{file.name}</Typography>}

          <FormControl fullWidth>
            <InputLabel id="type-label">{t("Type")}</InputLabel>
            <Select
              labelId="type-label"
              value={type}
              label={t("Type")}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value="LEADER">{t("Leader")}</MenuItem>
              <MenuItem value="STUDENT">{t("Student")}</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label={t("Description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />

          <TextField
            fullWidth
            label={t("Study ID")}
            value={estudoId}
            onChange={(e) => setestudoId(e.target.value)}
            required
          />

          <ErrorAlert error={error} />

          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? t("Uploading...") : t("Upload")}
          </Button>
        </Stack>
      </Card>
    </Container>
  );
}
