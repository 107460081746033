import { useTranslation } from "react-i18next";
import { keyframes } from "@mui/system";
import { Typography, Container, Box, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";

export async function loader() {
  // No data to load
  return {};
}

const animation = () => {
  const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  `;

  return { fadeIn, bounce };
};

export default function PreDonationConfirmationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fadeIn, bounce } = animation();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            animation: `${fadeIn} 0.5s ease-out 0.8s forwards`,
            opacity: 0,
          }}
        >
          <Box
            sx={{
              animation: `${bounce} 1s infinite`,
              display: "inline-block",
            }}
          >
            <CheckCircleOutlineIcon
              sx={{ fontSize: 60, color: "green", mb: 2 }}
            />
          </Box>
          <Typography variant="h4" gutterBottom>
            {t("Donation Confirmed!")}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {t("Your donation has been successfully received.")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoHome}
            sx={{ mt: 2 }}
          >
            {t("Go to Home")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
