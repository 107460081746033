/**
 * Call this when a form validation occurs. It will try to focus on the first
 * form field that has an error
 */
export default function formFocusOnError() {
  setTimeout(formFocusOnErrorImmediate, 10);
}

export function formFocusOnErrorImmediate() {
  const invalidElement = document.querySelector("[aria-invalid=true]");

  if (invalidElement && invalidElement.scrollIntoView != null) {
    invalidElement.scrollIntoView();
  }
}
