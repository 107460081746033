import React, { useEffect, useState } from "react";
import fetch from "data/fetch";
import Container from "@mui/material/Container";
import  { useRoles,useRegional, useCongregation } from "shared/hooks/usePermissions";

const METABASE_SITE_URL = "https://app.adbelem.org/metabase";

export default function MetabaseAnalyticsPageLayout({ dashboardId }) {
  const iframeUrl = useIframeUrl(dashboardId);

  if (!iframeUrl) {
    return (
      <Container>
        <p>No dashboard available for your role.</p>
      </Container>
    );
  }

  return (
    <Container
      disableGutters={true}
      sx={{
        flexGrow: 1,
        display: "flex",
      }}
    >
      <iframe
        title="Metabase Dashboard"
        src={iframeUrl}
        width="100%"
        height="100%"
        allowTransparency={true}
        frameBorder="0"
      />
    </Container>
  );
}

function useIframeUrl(dashboardId) {
  const [iframeUrl, setIframeUrl] = useState("");
  const roles = useRoles();
  const regional = useRegional();
  const congregation = useCongregation();

  useEffect(() => {
    const params = getParamsByRole(roles, regional, congregation);

    if (!params) {
      // If no params are determined, do not fetch the URL
      setIframeUrl("");
      return;
    }

    getURLMetabase(dashboardId, params)
      .then((signedUrl) => {
        setIframeUrl(signedUrl);
      })
      .catch((error) => {
        console.error("Error fetching Metabase signed URL:", error);
        setIframeUrl(""); // Ensure the iframe is hidden on error
      });
  }, [dashboardId, roles, regional, congregation]);

  return iframeUrl;
}

function getParamsByRole(roles, regional, congregation) {
  if (roles("MINISTERIAL") || roles("SYSTEM_ADMIN")) {
    return {};
  }

  if (roles("PASTOR_REGIONAL")) {
    return {
      regional: regional?.name,
    };
  }

  if (roles("PASTOR_LOCAL")) {
    return {
      regional: regional?.name,
      congregacao: congregation?.name,
    };
  }

  // Return null if no valid roles are found, to prevent the dashboard from displaying
  return null;
}

function getExpirationTime() {
  const now = new Date();
  const duration = 30 * 60 * 1000; // 30 minutes in milliseconds
  const expTime = new Date(now.getTime() + duration);
  return expTime.toISOString();
}

function getURLMetabase(dashboardId, params) {
  return fetch(`/v1/metabase-embed/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resource: {
        dashboard: dashboardId,
      },
      params,
      exp: getExpirationTime(),
    }),
  }).then((data) => {
    const token = data.data;
    return `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`;
  });
}
